import { useCallback, useState } from "react"
import { useEffect } from "react"
import DepositCoin from "./components/deposit"
import RecieveCoin from "./components/recieve"
import SwapCoin from "./components/swap"
import styles from '../../style/main.module.css'
import WithdrawCoin from "./components/withdraw"

const CoinMenu = () => {
    const [modalInfo, setModalInfo] = useState(<></>)
    const [modalType, setModalType] = useState()
    const [currency, setCurrency] = useState()
    const [currencySymbols, setCurrencySymbols] = useState()
    const [userId, setUserId] = useState()

    const getInfo = useCallback(() => {
        const userInfo = localStorage.getItem('_currentInfo')
        const users = JSON.parse(userInfo)
        const userCurrency = localStorage.getItem('OMS__CUR')
        const curr = JSON.parse(userCurrency)
        setCurrency(curr.name)
        setCurrencySymbols(curr.symbol)
        setUserId(users.user_id)
    }, [])
    useEffect(() => {
        getInfo()
    }, [getInfo])

    const OpenModal = (e) => {
        e.preventDefault()
        const modalType = e.target.id
        setModalType(modalType)
        setModalInfo("hello")
        if (modalType === 'Deposit') {

            setModalInfo(<DepositCoin id={userId} userFiat={currency} currencySym={currencySymbols} />)
        }
        else if (modalType === 'Recieve') {

            setModalInfo(<RecieveCoin id={userId} userFiat={currency} currencySym={currencySymbols} />)
        }
        else if (modalType === 'Swap') {

            setModalInfo(<SwapCoin id={userId} userFiat={currency} currencySym={currencySymbols} />)
        }
        else if (modalType === 'Withdraw') {

            setModalInfo(<WithdrawCoin id={userId} userFiat={currency} currencySym={currencySymbols} />)
        }
    }

    return (<>
        <div className={`${styles.card} ${styles.sm}`}>
            <div className={styles.card_inner}>
                <div className={styles.card_content}>
                    <div className={styles.card_body}>
                        <div className={styles.balance_body}>
                            <div className={styles.main_deposit}>
                                <div className={styles.card_list}>
                                    <div className={`${styles.display_flex} ${styles.align_center}`}>
                                        <div className={styles.card_list_lft}>
                                            <i className={`fi fi-sr-credit-card ${styles.card_list_inner_icon}`}></i>
                                        </div>
                                        <div className={styles.card_list_ctr}>
                                            <div className={styles.card_list_ctr_title}>Deposit Coin</div>
                                            <div className={styles.card_list_ctr_info}>
                                                Add coin to any of your wallet or request a new wallet
                                            </div>
                                        </div>
                                    </div>

                                    <button data-bs-toggle="modal" data-bs-target="#walletModal" className={` ${styles.wallet_btn} btn btn-warning btn-rounded`}
                                        id="Deposit" onClick={OpenModal}>Deposit</button>
                                </div>
                                <div className={styles.card_list}>
                                    <div className={`${styles.display_flex} ${styles.align_center}`}>
                                        <div className={styles.card_list_lft}>
                                            <i className={`fi fi-sr-gift ${styles.card_list_inner_icon}`}></i>
                                        </div>
                                        <div className={styles.card_list_ctr}>
                                            <div className={styles.card_list_ctr_title}>Recieve Coin</div>
                                            <div className={styles.card_list_ctr_info}>
                                                Copy and share wallet address to merchants and senders to recieve even when Offline
                                            </div>
                                        </div>
                                    </div>

                                    <button data-bs-toggle="modal" data-bs-target="#walletModal" className={` ${styles.wallet_btn} btn btn-warning btn-rounded`}
                                        id="Recieve" onClick={OpenModal}>Recieve</button>
                                </div>
                                <div className={styles.card_list}>
                                    <div className={`${styles.display_flex} ${styles.align_center}`}>
                                        <div className={styles.card_list_lft}>
                                            <i className={`fi fi-sr-shuffle ${styles.card_list_inner_icon}`}></i>
                                        </div>
                                        <div className={styles.card_list_ctr}>
                                            <div className={styles.card_list_ctr_title}>Swap Coin</div>
                                            <div className={styles.card_list_ctr_info}>
                                                Swap Your coin withen your Profolio Market available coin wallet faster and convenient
                                            </div>
                                        </div>
                                    </div>

                                    <button data-bs-toggle="modal" data-bs-target="#walletModal" className={` ${styles.wallet_btn} btn btn-warning btn-rounded`}
                                        id="Swap" onClick={OpenModal}>Swap</button>
                                </div>
                                <div className={styles.card_list}>
                                    <div className={`${styles.display_flex} ${styles.align_center}`}>
                                        <div className={styles.card_list_lft}>
                                            <i className={`fi fi-sr-money-from-bracket ${styles.card_list_inner_icon}`}></i>
                                        </div>
                                        <div className={styles.card_list_ctr}>
                                            <div className={styles.card_list_ctr_title}>Withdraw</div>
                                            <div className={styles.card_list_ctr_info}>
                                                Make Withdrawals in the most secure and easy way.
                                            </div>
                                        </div>
                                    </div>

                                    <button data-bs-toggle="modal" data-bs-target="#walletModal" className={` ${styles.wallet_btn} btn btn-warning btn-rounded`}
                                        id="Withdraw" onClick={OpenModal}>Withdraw</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="walletModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className={`  ${styles.card_inner} modal-content `}>
                    <div className={`${styles.card_header} modal-header`}>
                        <div className={`  ${styles.card_header_text}`} id="walletModalLabel">{modalType} Coin</div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className={`${styles.card_body} modal-body`}>
                        {
                            modalInfo

                        }

                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default CoinMenu;
import { Link } from "react-router-dom";
import AuthHeaders from "./AuthHeader";
import { Content, Main,  Text2, Title, Wrapper } from "./componentStyles";

const RegSuccess = () => {
    return (<>
    <AuthHeaders/>
    <Wrapper>
       

        <Main>
            <Title >Registration Completed</Title>
            <Content>
                <Text2>Please click the button below to login your account</Text2>
                <Link className="btn btn-warning" to='/login'> Return to Login</Link>
            </Content>
        </Main>

    </Wrapper></> );
}
 
export default RegSuccess;
import styles from '../../../style/main.module.css'
import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import CurrencyFormat from 'react-currency-format'
import '../../../../auth/css/loadProgressMin.css'
import { QRCode } from 'react-qrcode-logo'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { GetRandomString } from '../components/random'
import { AuthTextbox, AuthTextFlex, AuthTextgroup, FlexColumn, FlexRow,  H2, IconBody, IconDiv, TextCopied, TextLabel, TextListChild } from '../components/bodyStyle'

export const ProccesedData = ({ data }) => {
    const processed = data
    const [timer, setTimer] = useState(0)
    const [transActive, setTransActive] = useState(true)
    const checkTrans = useCallback((tradeId, walletAddress, coinAmount) => {
        axios.post('https://api.profoliomarket.com/auth/verifyPayments', { tradeId: tradeId, walletAddress: walletAddress, coinAmount: coinAmount })
            .then(() =>{// console.log(response)
    })
    }, [])

    const CountDownTimer =useCallback((startTime, endTime, transId) => {
        let months_arr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      

        let unixEndDate = new Date(endTime * 1000);
        let ey = unixEndDate.getFullYear();
        let em = months_arr[unixEndDate.getMonth()];
        let ed = unixEndDate.getDate();
        let eh = "0" + unixEndDate.getHours();
        let emn = "0" + unixEndDate.getMinutes();
        let es = "0" + unixEndDate.getSeconds();
        let realEndTimeFormat = em + ' ' + ed + ', ' + ey + ' ' + eh.substring(-2) + ':' + emn.substring(-2) + ':' + es.substring(-2);

        let EndTimeToUnix = new Date(realEndTimeFormat).getTime()

        let x = setInterval(function () {
            let realTime = new Date().getTime();
            let distance = EndTimeToUnix - realTime;
            let minutes = "0" + Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setTimer("-" + minutes.substring(-2) + ":" + seconds)
            if (distance < 0) {
                clearInterval(x);
                setTimer(<><div style={{ color: 'var(--failed)' }}>Expired</div></>)
                setTransActive(false)
                axios.post('https://api.profoliomarket.com/user/updateTransaction', 
                { transId:transId, status: 'failed', amount:'0' })
                .then(() => {//console.log(response.data)
            })
                
            }
            else if (distance < 10) {
                setTimer(<><div style={{ color: 'var(--warning)' }}>{"-" + minutes.substring(-2) + ":" + seconds}</div></>)
            }
        }, 1000);
    },[])

    
    const fullC = processed.coinFull
    const fullName = fullC
    const totalB = (processed.amount) + (processed.amount * 0.02)
    const totalC = (Number(processed.equv) + Number(processed.equv * 0.02)).toFixed(8)
    const startTime = processed.openDate
    const endTime = processed.closeDate
    const transId = processed.trans_id

    useEffect(() => {
        CountDownTimer(startTime, endTime, transId)
    }, [CountDownTimer, startTime, endTime,transId ])

    useEffect(() => {
       let x = setInterval(() => {
           
            if (transActive === false){
                clearInterval(x)
            }
            else(
                checkTrans(processed.trans_id, processed.wallet, processed.equv)
            )
        }, 30000)
    }, [checkTrans, processed, transActive])


    const [copiedShow, setCopiedShow] = useState(false)

    const hideState = ""
    const showState = <TextCopied>Copied</TextCopied>
    const toggleCopyAddress = () => {
        setCopiedShow(true);
        showAlert()
    };

    const showAlert = () => {
        setTimeout(() => {
            setCopiedShow(false);
        }, 2000);
    }
 
    return (
        <>{transActive ? <>
         <div className={styles.card_list_ctr_title}>
                Total: {(totalC)}{processed.coinType}
            </div>
            <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                Amount in {processed.fiat}:
                <CurrencyFormat value={totalB} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                    isNumericString={true} thousandSeparator={true} prefix={`${processed.syb}`} />
            </div>
            <div className={styles.deposit_wallet_text}>
                Scan the QR Code or copy and the payment details into your wallet
            </div>
         
            
            <FlexColumn style={{ alignItems: 'center' }}>
            <QRCode value={`${fullName}:${processed.wallet}?amount:${processed.amount}`} size={100} level={'M'}
                                                eyeRadius={[[10, 10, 0, 10], [10, 10, 10, 0], [10, 0, 10, 10],]}
                                                qrStyle={'squares'} style={{ borderRadius: '10px' }} className="rounded" />
            <TextListChild>
                <AuthTextgroup className="mb-4" style={{ marginTop: '10px' }}>
                    {copiedShow ? showState : hideState}
                    <AuthTextFlex>
                        <input type="radio" className="text" value={processed.wallet} style={{ display: "none" }} />
                        <AuthTextbox placeholder={processed.wallet} type="text" value="" disabled={true} />
                        <IconDiv className="icon-div">
                            <CopyToClipboard text={processed.wallet}
                                onCopy={() => toggleCopyAddress}>
                                <IconBody onClick={toggleCopyAddress}><i className="fi fi-sr-copy-alt"></i></IconBody>
                            </CopyToClipboard>

                        </IconDiv>
                    </AuthTextFlex>
                </AuthTextgroup>
            </TextListChild>
            </FlexColumn>
            <div className={styles.payment_page_title}>
                Network Charges:
                <div className={styles.payment_page_amount}>
                    {Number(processed.equv * 0.02).toFixed(8)}{processed.coinType}
                    <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                        =
                        <CurrencyFormat value={processed.amount * 0.02} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                            isNumericString={true} thousandSeparator={true} prefix={`${processed.syb}`} />
                        &nbsp;(0.2%)
                    </div>
                </div>
            </div>
            <div className={styles.payment_page_title}>
                Actual Amount:
                <div className={styles.payment_page_amount}>
                    {Number(processed.equv).toFixed(8)}{processed.coinType}
                    <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                        =
                        <CurrencyFormat value={processed.amount} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                            isNumericString={true} thousandSeparator={true} prefix={`${processed.syb}`} />
                    </div>
                </div>

            </div>
            <div className={styles.payment_page_title}>
                Transaction Expires:
                <div className={styles.payment_page_amount}>
                    {timer}
                </div>

            </div>
            <div className={styles.deposit_wallet_text}>
                For any transaction error or complain on <span style={{ color: 'var(--warning)' }}>{processed.trans_id}</span>, kindly contact support 
            </div>
            <div> <a href={`${fullName}:${processed.wallet}?amount:${processed.amount}`} rel={'noopener'} className={`btn ${styles.wallet_btn} btn btn-warning btn-rounded`} style={{ minWidth: '100%' }}>
                Pay in Wallet
            </a></div>
            </>:<>
            <FlexRow>
                
                    <TextLabel>
                        <H2 style={{ color: 'var(--failed)' }}>Transaction Expired</H2>
                        </TextLabel>
                </FlexRow>
                <div className={styles.deposit_wallet_text}>
                For any transaction error or complain on <span style={{ color: 'var(--warning)' }}>{processed.trans_id}</span>, kindly contact support
            </div>
            </>}
           

        </>
    )
}
const DepositCoinSingle = ({ walletAddress, coinFullName, coinShortName, walletId }) => {
    const userCurrency = JSON.parse(localStorage.getItem('OMS__CUR'))
    const Fiat = userCurrency.name
    const currSym = userCurrency.symbol
    const userId = JSON.parse(localStorage.getItem('_currentInfo')).user_id
    const [coinEquv, setCoinEquv] = useState(0)
    const [mainCur, setMainCur] = useState(0)
    const [proceedBtn, setProceedBtn] = useState('Proceed')



    const getEquiv = (e) => {
        e.preventDefault();
        const init = Number(e.target.value)
        axios.post(`https://min-api.cryptocompare.com/data/price?fsym=${Fiat}&tsyms=${coinShortName}`)
            .then((response) => {

                const price = response.data
                const newPrice = JSON.stringify(price)
                let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z/gm, '');
                const newCryptoRate = (init * Number(strip)).toFixed(8)
                setMainCur(init)
                setCoinEquv(newCryptoRate)
            }).catch((error) => {
                console.log(error)
            })
    }

    // const data = coinList

    const GenerateTransaction = (e) => {
        e.preventDefault();

        const t_id = GetRandomString(16)
        const trans_data = {
            trans_id: t_id,
            amount: mainCur,
            equv: coinEquv,
            coinType: coinShortName,
            fiat: Fiat,
            userId: userId,
            syb: currSym,
            walletId: walletId,
            wallet: walletAddress,
            coinFull: coinFullName,
            openDate: Math.floor(Date.now() / 1000),
            closeDate: Math.floor((Date.now() / 1000) + 600),
        }
       // console.log(trans_data)
        setProceedBtn(<div className="lds-ring"><div></div><div></div><div></div><div></div></div>)

        axios.post('https://api.profoliomarket.com/user/createTransaction',
            {
                trans_id: t_id,
                amount: mainCur,
                equv: coinEquv,
                coinType: coinShortName,
                fiat: Fiat,
                userId: userId,
                trans_type: 'deposit',
                date: Math.floor(Date.now() / 1000),
                status: "pending",
                walletId: walletId
            })
            .then((response) => {
                return response.data
            }).then((data) => {
                setShowProcessed(!showProcessed)
                setProcessedData(<><ProccesedData data={trans_data} /></>)

            }).catch((error) => {
                console.log(error)
            })
    }
    const [proccesed, setProcessedData] = useState()
    const [showProcessed, setShowProcessed] = useState(false)
    return (<>{
        showProcessed ? proccesed :
            <form onSubmit={GenerateTransaction}>

                <div className="input-group mb-3">
                    <input type="text" className={` ${styles.text_input} form-control`} placeholder={`Enter Amount in ${Fiat}`} name="txtbitamount" onChange={getEquiv} />
                    <span className={`${styles.modal_coin} input-group-text`} id="basic-addon2">
                        <div className="dropdown">
                            <button className={`${styles.modal_coin_button} btn`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className={styles.coin_table_logo} alt={styles.coin_name} src={require(`../../../style/SVG/${coinShortName}.svg`)} />

                            </button>

                        </div>
                    </span>
                </div>
                <div className={styles.coin_deposit_base}>
                    <button className="btn btn-success" style={{ minWidth: '100%' }}>{proceedBtn}</button>
                    <div className={`ms-auto ${styles.deposit_equivalent}`}>
                        <CurrencyFormat value={mainCur} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                            isNumericString={true} thousandSeparator={true} prefix={`${currSym}`} />
                        <i className={`fi fi-rr-exchange ${styles.deposit_exchanger_icon}`}></i>{coinEquv} {coinShortName}
                    </div>

                </div>

            </form>
    }

    </>);
}


export default DepositCoinSingle;
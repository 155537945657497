import Login from './auth/login';
import Register from './auth/register';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Forgot from './auth/forgot';
import Dashboard from './market/home';
import UserDashboard from './market/pages/dashboard';
import DashboardTrade from './market/pages/trade';
import DashboardPayments from './market/pages/payments';
import Wallet from './market/pages/wallet/wallet';
import ExternalWallet from './market/pages/wallet/external';
import SpotWallet from './market/pages/wallet/spot';
import FundingWallet from './market/pages/wallet/funding';
import VerifyMail from './auth/components/verifyEmail';
import ConfirmMail from './auth/components/confirmEmail';
import React from 'react';
import SetCurrency from './auth/components/setCurrency';
import Verification from './market/pages/verification';
import CoinSingle from './market/pages/wallet/coin';
import BlogList from './market/pages/blogs/blogs';
import IndexHome from './landing';
import IndexWelcomePage from './landing/pages/home';
import IndexAbout from './landing/pages/about';
import NoInternetConnection from './services/NetworkChecker';
import ContinueRegister from './auth/otherReg';
import Page404 from './auth/components/404';
import WallectConnect from './market/pages/connectWallet/walletConnect';
import Settings from './market/pages/settings/settings';
import LoginOTP from './auth/otp';
import Affiliate from './market/pages/affiliate';
import IndexMarket from './landing/pages/market';
import IndexHelp from './landing/pages/help';
import IndexInsights from './landing/pages/insights';
import HelpCategory from './landing/pages/helpCategory';
import RegSuccess from './auth/components/regSuccess';
import CacheBuster from './CatchBuster';
function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <Router>
            <div className="App">
              <NoInternetConnection>
                <Routes>

                  <Route path="/home" element={<Dashboard />}>
                    <Route index element={<UserDashboard />} />
                    <Route path="/home/order" element={<DashboardTrade />} />
                    <Route path="/home/blog" element={<BlogList />} />
                    <Route path="/home/connectWallect" element={<WallectConnect />} />
                    <Route path="dashboard" element={<UserDashboard />} />
                    <Route path="order" element={<DashboardTrade />} />
                    <Route path="finance" element={<DashboardPayments />} />
                    <Route path="security" element={<Settings />} />
                    <Route path="verification" element={<Verification />} />
                    <Route path="affiliate" element={<Affiliate />} />
                    <Route path="wallet" element={<Wallet />} />
                    <Route path="wallet/external" element={<ExternalWallet />} />
                    <Route path="wallet/spot" element={<SpotWallet />} />
                    <Route path="wallet/funding" element={<FundingWallet />} />
                    <Route path="wallet/:userId/:walletId" element={<CoinSingle />} />
                    <Route path="wallet/spot/:userId/:walletId" element={<CoinSingle />} />
                  </Route>


                  <Route index element={<Navigate replace to="/web" />} />
                  <Route path="/web" element={<IndexHome />} >
                    <Route index element={<IndexWelcomePage />} />
                    <Route path="about" element={<IndexAbout />} />
                    <Route path="market" element={<IndexMarket />} />
                    <Route path="help" element={<IndexHelp />} />
                    <Route path="help/:id" element={<HelpCategory />} />
                    <Route path="insights" element={<IndexInsights />} />
                  </Route>

                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/verify" element={<VerifyMail />} />
                  <Route path="/confirm/:email/:id/:userId" element={<ConfirmMail />} />
                  <Route path="/forgot" element={<Forgot />} />
                  <Route path="/currency" element={<SetCurrency />} />
                  <Route path="/auth" element={<LoginOTP />} />
                  <Route path="/userBios" element={<ContinueRegister />} />
                  <Route path="/regCompleted" element={<RegSuccess />} />
                  <Route path="*" element={<Page404 />} />



                </Routes>
              </NoInternetConnection>
            </div>
          </Router>

        )}}
    </CacheBuster>
  );
}

export default App;

import styles from '../../../style/main.module.css'
import { GetRandomString } from './random'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import '../../../../auth/css/loadProgressMin.css'
class SwapCoin extends React.Component {
    state = {
        transProcessed: false,
        processedData: [],
        value: '',
        copied: false,
    }
    render() {
        const DepositModal = (props) => {
            const id = props.item.id
            const [coinFrom, setCoinFrom] = useState('BTC')
            const [coinTo, setCoinTo] = useState('ETH')
            const [coinFromBalance, setCoinFromBalance] = useState('0.00000000')
            const [coinToBalance, setCoinToBalance] = useState('0.00000000')
            const [coinEquv, setCoinEquv] = useState(0)
            const [coinList, setCoinList] = useState()
            const [mainCur, setMainCur] = useState(0)
            const [proceedBtn, setProceedBtn] = useState('Swap')
            const [walletId, setWallet] = useState()
            const [walletTo, setWalletTo] = useState()
            useEffect(() => {
                const fetchCoin = (async () => {
                    await axios.post('https://api.profoliomarket.com/user/userCoin',
                        { userID: id })
                        .then((response) => {
                            return response.data.data
                        }).then((data) => {
                            setCoinList(data)
                            console.log(data)
                            setCoinFromBalance(data[0].balance)
                            setCoinFrom(data[0].short_name)
                            setCoinToBalance(data[1].balance)
                            setCoinTo(data[1].short_name)
                        }).catch((error) => {
                            console.log(error)
                        })

                })
                fetchCoin()
            }, [id])

            const getEquiv = (e) => {
                e.preventDefault();
                const init = Number(e.target.value)
                axios.post(`https://min-api.cryptocompare.com/data/price?fsym=${coinFrom}&tsyms=${coinTo}`)
                    .then((response) => {

                        const price = response.data
                        const newPrice = JSON.stringify(price)
                        let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z/gm, '');
                        const newCryptoRate = (init * Number(strip)).toFixed(8)
                        setMainCur(init)
                        setCoinEquv(newCryptoRate)
                    }).catch((error) => {
                        console.log(error)
                    })
            }

            const data = coinList

            const GenerateTransaction = (e) => {
                e.preventDefault();

                setProceedBtn(<div className="lds-ring"><div></div><div></div><div></div><div></div></div>)
                const fee = Number(mainCur * 0.05)
                const totalC = Number(mainCur) + fee;
                if (mainCur > coinFromBalance || mainCur === "" || mainCur <= 0 || totalC > coinFromBalance) {
                    alert("Invalid Amount Entred: Sorry the amount entries does not meet the minimum amount requirements, kindly check to see if you have sufficient funds")
                    setProceedBtn('Swap')
                }
                else {

                    const t_id = GetRandomString(8)
                    const trans_data ={
                        trans_id: 't-'+ t_id,
                        coinFrom: coinFrom,
                        coinTo: coinTo,
                        convertFrom: Number(totalC).toFixed(8), 
                        convertTo:coinEquv,
                        userId: id,
                        trans_type:'swap',
                        date : Math.floor(Date.now() / 1000),
                        status : "pending"
                    } 

                    axios.post('https://api.profoliomarket.com/user/createTransaction',{
                            trans_id: GetRandomString(16),
                            equv: Number(totalC).toFixed(8),
                            coinType: coinFrom, 
                            fiat: coinTo,
                            userId: id,
                            trans_type: 'swap',
                            date: Math.floor(Date.now() / 1000),
                            status: "pending",
                            walletId: walletId
                        }).then(() => {
                        
                            axios.post('https://api.profoliomarket.com/user/createTransaction',{
                                    trans_id:GetRandomString(16),
                                    equv:coinEquv,
                                    coinType: coinTo,
                                    fiat: coinFrom,
                                    userId: id,
                                    trans_type: 'withdraw',
                                    date: Math.floor(Date.now() / 1000),
                                    status: "pending",
                                    walletId: walletTo,
                                }).then(() => {
                                        this.setState({transProcessed: true})
                                        this.setState({processedData: trans_data})
                                }).catch((error) => {
                                    console.log(error.response)
                                })


                        }).catch((error) => {
                            console.log(error.response)
                        })
                }
            }
            return (<>
                <form onSubmit={GenerateTransaction}>
                    <div className="input-group mb-3">
                        <div className={styles.swap_coin_input}>
                            <input type="text" className={` ${styles.text_input} form-control`} placeholder={`${mainCur} ${coinFrom}`} name="txtbitamount" onChange={getEquiv} />
                            <div className={styles.swap_coin_text_balance}>
                                <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                                    Balance: {coinFromBalance}{coinFrom}
                                </div>
                            </div>
                        </div>

                        <span className={`${styles.modal_coin} ${styles.align_base} input-group-text`} id="basic-addon2">
                            <div className="dropdown">
                                <button className={`${styles.modal_coin_button} btn dropdown-toggle`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img className={styles.coin_table_logo} alt={styles.full_name} src={require(`../../../style/SVG/${coinFrom}.svg`)} />
                                </button>
                                {
                                    data && <ul className={`dropdown-menu ${styles.dropdown_m} `} aria-labelledby="dropdownMenuButton1">
                                        {data.map((items) => (

                                            <li key={items.wallet_id} ><div className={` ${styles.dropdown_z} dropdown-item`} id={items.short_name} onClick={(e) => { setCoinFrom(items.short_name); setCoinFromBalance(items.balance); setWallet(items.wallet_id); }}>
                                                <img className={styles.coin_deposit_logo} alt={styles.full_name} src={require(`../../../style/SVG/${items.short_name}.svg`)} onClick={(e) => { setCoinFrom(items.short_name); setCoinFromBalance(items.balance); setWallet(items.wallet_id); }} />
                                                <div className={styles.coin_deposit_full_name} onClick={(e) => { setCoinFrom(items.short_name); setCoinFromBalance(items.balance); setWallet(items.wallet_id); }}>{items.short_name}</div> </div></li>

                                        ))}
                                    </ul>
                                }
                            </div>

                        </span>

                    </div>
                    <div className={` ${styles.justify_center} input-group mb-3`}>
                        <i className={`fi fi-rr-exchange ${styles.swap_coin_equator} ${styles.deposit_exchanger_icon}`}></i>
                    </div>
                    <div className="input-group mb-3">
                        <div className={styles.swap_coin_input}>
                            <input type="text" className={` ${styles.text_input} ${styles.wallet_address} form-control`} name="txtbitamount" onChange={getEquiv} placeholder={`${coinEquv} ${coinTo}`} disabled />
                            <div className={styles.swap_coin_text_balance}>
                                <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                                    Balance: {coinToBalance}{coinTo}
                                </div>
                            </div>
                        </div>

                        <span className={`${styles.modal_coin} ${styles.align_base} input-group-text`} id="basic-addon2">
                            <div className="dropdown">
                                <button className={`${styles.modal_coin_button} btn dropdown-toggle`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img className={styles.coin_table_logo} alt={styles.full_name} src={require(`../../../style/SVG/${coinTo}.svg`)} />
                                </button>
                                {
                                    data && <ul className={`dropdown-menu ${styles.dropdown_m} `} aria-labelledby="dropdownMenuButton1">
                                        {data.map((items) => (

                                            <li key={items.wallet_id} ><div className={` ${styles.dropdown_z} dropdown-item`} id={items.short_name} onClick={(e) => { setCoinTo(items.short_name); setCoinToBalance(items.balance); setWalletTo(items.wallet_id) }}>
                                                <img className={styles.coin_deposit_logo} alt={styles.full_name} src={require(`../../../style/SVG/${items.short_name}.svg`)} onClick={(e) => { setCoinTo(items.short_name); setCoinToBalance(items.balance); setWalletTo(items.wallet_id) }} />
                                                <div className={styles.coin_deposit_full_name} onClick={(e) => { setCoinTo(items.short_name); setCoinToBalance(items.balance); setWalletTo(items.wallet_id) }}>{items.short_name}</div> </div></li>

                                        ))}
                                    </ul>
                                }
                            </div>

                        </span>

                    </div>
                    <div className={styles.coin_deposit_base}>
                        <button className="btn btn-success" style={{ minWidth: '100%' }}>{proceedBtn}</button>
                        <div className={`ms-auto ${styles.deposit_equivalent}`}>
                            {Number(mainCur).toFixed(8)}{coinFrom}
                            <i className={`fi fi-rr-exchange ${styles.deposit_exchanger_icon}`}></i>{coinEquv}{coinTo}
                        </div>

                    </div>

                </form>
            </>);
        }
        const TransPaymentProcessor = () => {
            const processed = this.state.processedData
            const fee = Number(processed.convertFrom * 0.05)
            const totalC = Number(processed.convertFrom) + fee
            return (
                <>
                    <div className={styles.card_list_ctr_title}>
                        Swap Coin Pending
                    </div>
                    <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                        Convert from {processed.coinFrom} to {processed.coinTo}
                    </div>
                    <div className={styles.payment_page_title}>
                        Transfar Amount:
                        <div className={styles.payment_page_amount}>
                            {processed.convertFrom}{processed.coinFrom}
                            <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                                =
                                {processed.convertTo}{processed.coinTo}

                            </div>
                        </div>
                    </div>
                    <div className={styles.payment_page_title}>
                        Transfer Fee:
                        <div className={styles.payment_page_amount}>
                            {fee.toFixed(8)}{processed.coinFrom}
                            <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                                (0.05%)
                            </div>
                        </div>

                    </div>
                    <div className={styles.payment_page_title}>
                        Total Amount:
                        <div className={styles.payment_page_amount}>
                            {totalC.toFixed(8)}{processed.coinFrom}

                        </div>

                    </div>
                    <div className={styles.deposit_wallet_text}>
                        For any transaction error or complain on <br /><strong>{processed.trans_id}</strong>, kindly visit the support channel
                    </div>

                </>
            )
        }
        const x = this.state.transProcessed

        return (<>
            {x ? '' : <DepositModal item={this.props} />}
            {x && (<TransPaymentProcessor />)}
        </>)
    }
}


export default SwapCoin;
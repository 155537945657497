import { useEffect, useState } from 'react';
import styles from '../css/index.module.css'
import { ButtonLg, ButtonWrapper, Card, CardBody, CardContent, CardHeader, CardHeaderText, CardInner, CardInnerTransparency, Column, ColumnItem1, ColumnItem2, ColumnRow, H2, H3, IndexSection, IndexSectionDescription, IndexSectionWrapper, LargeImage, Paragraph, ParagraphList, ParagraphListImg, ParagraphListImgWrapper, ParagraphListItem, ParagraphText, Row, Text, Text2, TextIcon, TextIconWrapper } from '../style/bodyStyle';
import { Link, useNavigate } from 'react-router-dom'
import PairList from '../../market/pages/dashboard/pairList';
const IndexWelcomePage = () => {

    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(true)
    useEffect(() => {

        const handleResize = () => {
            if (window.innerWidth > 767) {
                setIsMobile(false)
            }
            else if (window.innerWidth < 767) {
                setIsMobile(true)
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)
    }, [isMobile])



    return (<>
        <div className={styles.main_container}>
            <div className={styles.index_welcome}>
                <div className={styles.index_welcome_left}>
                    <h1 className={styles.left_welcome}>
                        <div className={styles.left_welcome_text}>
                            Easy, Proficient and Reliable way to trade
                        </div>
                    </h1>
                    <div className={styles.left_welcome_bottom}>
                        <div className={styles.left_welcome_button_content}>
                            <ButtonLg className="btn" onClick={(e) => { navigate('/register') }}>Get Started for Free</ButtonLg>
                        </div>
                    </div>
                </div>
                {isMobile ? <> <div className={styles.index_welcome_right}>
                    <div className={styles.index_welcome_right_content}>
                        <img className={styles.index_welcome_img} alt="top_lazy_img" src={require(`../../images/mobile_pages.png`)} />
                    </div>


                </div></>
                    : <>
                        <div className={styles.index_welcome_right}>
                            <div className={styles.index_welcome_right_content}>
                                <img className={styles.index_welcome_img} alt="top_lazy_img" src={require(`../../images/desktop_page.png`)}  />
                            </div>


                        </div>
                    </>}


            </div>
            <div className={styles.index_list_view}>
                <div className={styles.index_list_item}>
                    <div className={styles.index_list_item_content}>
                        <div className={styles.index_list_item_header}>
                            Real-Time
                        </div>
                        <div className={styles.index_list_item_body}>
                            Expirience our 24hrs real time market data
                        </div>
                    </div>

                </div>
                <div className={styles.index_list_item}>
                    <div className={styles.index_list_item_content}>
                        <div className={styles.index_list_item_header}>
                            Fast Transactions
                        </div>
                        <div className={styles.index_list_item_body}>
                            Process financial payments quicker
                        </div>
                    </div>
                </div>
                <div className={styles.index_list_item}>
                    <div className={styles.index_list_item_content}>
                        <div className={styles.index_list_item_header}>
                            Low Fees
                        </div>
                        <div className={styles.index_list_item_body}>
                            Lower Transactions fees for all Transactions
                        </div>
                    </div>
                </div>
            </div>
            <IndexSection>
                <H2>
                    Latest Market Infomation
                </H2>
                <IndexSectionDescription>
                    New features, latest data
                </IndexSectionDescription>
                <div className={styles.index_table}>
                    <PairList />
                </div>
                <Paragraph>
                    <H3 data-bn-type="text">
                        Sign up now to build your own finance empire today!
                    </H3>
                    <ButtonLg className="btn" onClick={(e) => { navigate('/register') }}>Get Started</ButtonLg>
                </Paragraph>
            </IndexSection>
            <IndexSection>
                <IndexSectionWrapper>
                    <H2>
                        Easy Steps to get started on any device
                    </H2>
                    <Paragraph>
                        <ParagraphText data-bn-type="text">
                            Either on your desktop or on mobile.
                        </ParagraphText>
                    </Paragraph>
                    <ColumnRow>
                        <ColumnItem1>
                            <Paragraph >
                                <ParagraphList>
                                    <ParagraphListImgWrapper>
                                        <ParagraphListImg className="fi fi-sr-portrait">
                                        </ParagraphListImg>
                                    </ParagraphListImgWrapper>
                                    <ParagraphListItem>
                                        <H3>Fund your account</H3>

                                        <Text>
                                            <Text2 >Add funds to your crypto account to start trading crypto. You can add funds
                                                with a variety of payment methods.</Text2>
                                        </Text>
                                    </ParagraphListItem>
                                </ParagraphList>
                                <ParagraphList>
                                    <ParagraphListImgWrapper>
                                        <ParagraphListImg className="fi fi-sr-id-badge">
                                        </ParagraphListImg>
                                    </ParagraphListImgWrapper>
                                    <ParagraphListItem>
                                        <H3>Verify your Identity</H3>

                                        <Text>
                                            <Text2 >Complete the identity verification process to boost your trading account.</Text2>
                                        </Text>
                                    </ParagraphListItem>
                                </ParagraphList>
                                <ParagraphList>
                                    <ParagraphListImgWrapper>
                                        <ParagraphListImg className="fi fi-sr-coins">
                                        </ParagraphListImg>
                                    </ParagraphListImgWrapper>
                                    <ParagraphListItem>
                                        <H3>Grow your finance</H3>

                                        <Text>
                                            <Text2 >You're all set and ready to go!. Explore all our features and offers.</Text2>
                                        </Text>
                                    </ParagraphListItem>
                                </ParagraphList>
                            </Paragraph>
                            <ButtonLg className="btn" onClick={(e) => { navigate('/register') }}>Get Started</ButtonLg>
                        </ColumnItem1>

                        <ColumnItem2>
                            <LargeImage alt="" className="mica-lazy-img" src={require('../../images/mobile_pages.png')} style={{ backgroundColor: 'transparent' }} />
                        </ColumnItem2>
                    </ColumnRow>
                </IndexSectionWrapper>
            </IndexSection>
            <IndexSection>
                <IndexSectionWrapper>
                    <ColumnRow style={{ alignItems: 'center' }}>
                        <ColumnItem1>
                            <Row>

                                <Card>
                                    <CardInner>
                                        <CardContent>
                                            <CardHeader>
                                                <CardHeaderText>
                                                    <i className="fi fi-sr-kite"></i>
                                                </CardHeaderText>

                                            </CardHeader>
                                            <CardBody>
                                                <Paragraph>
                                                    <ParagraphText>
                                                        Zero Risk Trades
                                                    </ParagraphText>
                                                    <Text>
                                                        Explore the platform and test strategies for free.

                                                    </Text>
                                                </Paragraph>

                                            </CardBody>
                                        </CardContent>

                                    </CardInner>

                                </Card>
                                <Card>
                                    <CardInner>
                                        <CardContent>
                                            <CardHeader>
                                                <CardHeaderText>
                                                    <i className="fi fi-sr-asterik"></i>
                                                </CardHeaderText>

                                            </CardHeader>
                                            <CardBody>
                                                <Paragraph>
                                                    <ParagraphText>
                                                        Demo Trading
                                                    </ParagraphText>
                                                    <Text>
                                                        10,000 in virtual funds to practice and learn.

                                                    </Text>
                                                </Paragraph>

                                            </CardBody>
                                        </CardContent>

                                    </CardInner>

                                </Card>
                                <Card>
                                    <CardInner>
                                        <CardContent>
                                            <CardHeader>
                                                <CardHeaderText>
                                                    <i className="fi fi-sr-signal-alt-2"></i>
                                                </CardHeaderText>

                                            </CardHeader>
                                            <CardBody>
                                                <Paragraph>
                                                    <ParagraphText>
                                                        Market
                                                    </ParagraphText>
                                                    <Text>
                                                        Elevate your trading skills with our unique tools.

                                                    </Text>
                                                </Paragraph>

                                            </CardBody>
                                        </CardContent>

                                    </CardInner>

                                </Card>
                                <Card>
                                    <CardInner>
                                        <CardContent>
                                            <CardHeader>
                                                <CardHeaderText>
                                                    <i className="fi fi-sr-business-time"></i>
                                                </CardHeaderText>

                                            </CardHeader>
                                            <CardBody>
                                                <Paragraph>
                                                    <ParagraphText>
                                                        Personal Manager
                                                    </ParagraphText>
                                                    <Text>
                                                        Get exclusive one-on-one guidance with a trading expert.

                                                    </Text>
                                                </Paragraph>

                                            </CardBody>
                                        </CardContent>

                                    </CardInner>

                                </Card>
                            </Row>
                        </ColumnItem1>
                        <ColumnItem2>
                            <H2>
                                Why Trade with us?
                            </H2>
                            <Paragraph>
                                <ParagraphText data-bn-type="text">
                                    We are a Platform that Works just for You.
                                </ParagraphText>
                            </Paragraph>
                        </ColumnItem2>
                    </ColumnRow>

                </IndexSectionWrapper>
            </IndexSection>
            <IndexSection>
                <IndexSectionWrapper>

                    <Column>
                        <H2>Need Help?</H2>
                        <Row>
                            <Card>
                                <CardInnerTransparency>
                                    <CardContent>
                                        <CardBody>
                                            <ParagraphList>
                                                <ParagraphListImgWrapper>
                                                    <ParagraphListImg className="fi fi-sr-comment-info">
                                                    </ParagraphListImg>
                                                </ParagraphListImgWrapper>
                                                <ParagraphListItem>
                                                    <H3>Get Support</H3>

                                                    <Text>
                                                        <Text2 >Get 24/7 chat support with our customer service agents at your service.</Text2>
                                                    </Text>
                                                    <TextIconWrapper>
                                                        <Link to="help">
                                                            <Text2>
                                                                Chat now
                                                            </Text2>
                                                            <TextIcon className="fi fi-rr-arrow-small-right"></TextIcon>
                                                        </Link>
                                                    </TextIconWrapper>
                                                </ParagraphListItem>
                                            </ParagraphList>
                                        </CardBody>
                                    </CardContent>
                                </CardInnerTransparency>
                            </Card>
                            <Card>
                                <CardInnerTransparency>
                                    <CardContent>
                                        <CardBody>
                                            <ParagraphList>
                                                <ParagraphListImgWrapper>
                                                    <ParagraphListImg className="fi fi-rr-screen">
                                                    </ParagraphListImg>
                                                </ParagraphListImgWrapper>
                                                <ParagraphListItem>
                                                    <H3>Blogs</H3>

                                                    <Text>
                                                        <Text2 >Stay up to date with the daily and latest stories On markets and commentary.</Text2>
                                                    </Text>
                                                    <TextIconWrapper>
                                                        <Link to="insights">
                                                            <Text2>
                                                                Learn more
                                                            </Text2>
                                                            <TextIcon className="fi fi-rr-arrow-small-right"></TextIcon>
                                                        </Link>
                                                    </TextIconWrapper>
                                                </ParagraphListItem>
                                            </ParagraphList>
                                        </CardBody>
                                    </CardContent>
                                </CardInnerTransparency>
                            </Card>
                        </Row>

                    </Column>
                </IndexSectionWrapper>
            </IndexSection>
            <IndexSection>
                <IndexSectionWrapper>
                    <Column>

                        <H2 style={{ textAlign: 'center' }}>
                            Start earning today
                        </H2>
                        <ButtonWrapper>
                            <ButtonLg className="btn" onClick={(e) => { navigate('/register') }}>Get Started</ButtonLg>

                        </ButtonWrapper>

                    </Column>
                </IndexSectionWrapper>
            </IndexSection>


        </div>

    </>);
}

export default IndexWelcomePage;
import { Link } from "react-router-dom";
import { Paragraph } from "../style/bodyStyle";
import { Footer, Flex, FooterTop, Container, Card, Column, FooterTitle, FooterList, FooterSocial, Row, ColumnRow, RowColumn } from "../style/footerStyle";

const IndexFooter = () => {
    return ( <>
   <Footer>
        <FooterTop className="footer-top" >  
        <Container>
                    <Flex>
                        <Card>
                            <Column>
                                <FooterTitle>Profolio Market</FooterTitle>
                                <FooterList>
                                    <Link to="about" >About Us</Link>
                                    <Link to="steps" >How it works</Link>

                                </FooterList>
                            </Column >
                        </Card>
                        
                        <Card>
                            <Column>
                                <FooterTitle>Help</FooterTitle>
                                <FooterList>
                                    <Link to="help" >Help Center</Link>
                                    <Link to="#" >Support</Link>
                                    <Link to="#" >Privacy/Policy</Link>
                                </FooterList>
                            </Column>
                        </Card>
                        <Card>
                            <Column>
                                <FooterTitle>Analytics</FooterTitle>
                                <FooterList>
                                    <Link  to="market" >Markets</Link>
                                    <Link to="insights" >Insights</Link>
                                </FooterList>
                            </Column>
                        </Card>
                        <Card>
                            <Column >  
                            <FooterTitle>Join the Community</FooterTitle>
                                <FooterSocial>
                                   <Link to="#"><i className="fi fi-brands-twitter"></i></Link>
                                   <Link to="#"><i className="fi fi-brands-twitch"></i></Link>
                                   <Link to="#"><i className="fi fi-brands-reddit"></i></Link>
                                   <Link to="#"><i className="fi fi-brands-instagram"></i></Link>
                                   <Link to="#"><i className="fi fi-brands-facebook"></i></Link>
                                </FooterSocial>
                            </Column>
                        </Card>
                    </Flex>
                </Container>
        </FooterTop>
        <div className="footer-bottom">
            <div className="container">
                <RowColumn>
                    <Column style={{flex: 1}}>
                 
                    <p className="text-center py-4 mb-0" >All rights reserved &copy; Profolio Market
                </p>
                    </Column>
                
                <Paragraph style={{flex: 1, fontSize: 12}}>
                    <p className=" py-4 mb-0">
                    Crypto Currencies are not legal tender and are not backed by any government. Accounts and value balances are not subject 
                    to any government backed deposit insurance or any other government protections.  
                    </p>
                    </Paragraph>
                 
                </RowColumn>
               
            </div>
        </div>

    </Footer>

    </> );
}
 
export default IndexFooter;
import '../css/font-awesome.min.css';
import '../css/style.css'
import styles from '../css/auth.module.css'
import { useNavigate, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { Main, Title, Content, Text, Wrapper,  Card, CardHeader, CardBody, CurrencyList, Span, FlexColumn } from './componentStyles';
import '../../market/style/flag-icons-main/css/flag-icons.min.css'
import { CountryDropdown} from 'react-country-region-selector';
import Page404 from './404';
import axios from 'axios';
import AuthHeaders from './AuthHeader';
//import Cookies from "js-cookies"
const SetCurrency = () => {
            var items = useLocation()
            const navigate = useNavigate();
            const [fiatList, setFiatList] = useState([])
            const [country, setCountry] = useState()
            const [currencySelected, setCurrencySelected] = useState()
            const [currency_id, setCurrency_id] = useState()
            useEffect(() => {
                axios.post('https://api.profoliomarket.com/user/getAllFiats',{})
                    .then((response) => {
                    //    console.log(response.data.data)
                        setFiatList(response.data.data)
                    }).catch((error) => {
                        console.log(error.response)
                    })
            }, [])
          
            
            const processCurrency = (e) => {
                e.preventDefault();
                setProceedBtn(false)
                 const payload = {
                country: country,
                currency_id: currency_id,
                userId: items.state.userId
            }
              
                axios.post('https://api.profoliomarket.com/user/updateCurrency',payload)
                .then((response) => {
                    console.log(response.data)
                    //Assign Coin BTC, ETH, USDT
                    axios.post('https://api.profoliomarket.com/user/addAdminWallet', {userId:items.state.userId})
                        .then(res=>{
                            console.log(res.data)
                           navigate('/regCompleted')
                        }).catch(error => {
                           console.error(error.response);
                      });
                    
                   
                })
                .catch((error) => {
                    console.log(error.response);
                    setProceedBtn( true)

                })
               
            }
            const [proceedBtn, setProceedBtn]= useState(true);
            const [activeId, setActiveId] = useState();

            if (items.state === null) {
                return (<>
                    <Page404 />
                </>);
            }
            else {

                //const email = items.state.email
               // const userId = items.state.userId
                const data = fiatList

          
                return (<>
                <AuthHeaders/>
                    <Wrapper styling={`justify-content: center; margin: 70px auto; padding: 40px; ; height:100% !important`}>
                        {/* <Logo>
                        <Link className="d-block" to="" style={{ width: 'fit-content' }}>
                            <img className="img-responsive" src={logo} alt="logo" />
                        </Link> 
                    </Logo> */}
                        <Main>
                            <Title >Currency and<span className='text-warning'> Country</span></Title>
                            <Content styling={` width:350px; @media screen and (min-width: 768px){width:600px }`}>
                            <Card>
                                    <CardHeader>
                                        <Text className="text-warning">Choose a country and region </Text>
                                    </CardHeader>
                                    <CardBody>
                                    <FlexColumn>
                                    <CountryDropdown  className={styles.selectContainer} value={country} onChange={(val) => {setCountry(val);}} />                                    
                                    </FlexColumn>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader>
                                        <Text className="text-warning">Select your currency</Text>
                                    </CardHeader>
                                    {data && <CardBody>
                                        {data.map((items, index) => (

                                            <CurrencyList  id={index} className={`${activeId === index && 'active'}`} onClick={(e) => { e.preventDefault(); setCurrencySelected(`You have selected  ${items.name}  -  ${items.symbol.substring(1, 2)} as your default currency`); setCurrency_id(items.id);  setActiveId(index);}} key={index} >

                                                <Span>{items.name} - {items.symbol.substring(1, 2)}</Span>

                                            </CurrencyList>

                                        ))}

                                    </ CardBody>}
                                    <Span> {currencySelected}  </Span>
                                </Card>
                                { proceedBtn ? <button className="btn btn-warning" onClick={processCurrency}  disabled={!currency_id || !country ? true : false} >Proceed</button> : 
                                <button className="btn btn-warning" type="submit" disabled={true}>
					                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                    </button>} 
                            </Content>
                          
                        </Main>
                    </Wrapper>

                </>);
            }
        }
export default SetCurrency;
import styles from '../../../style/main.module.css'
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { FlexColumn, IconBTN, LoadingLg } from './style';
const CoinPrice = (props) => {
  const coin = props.coinName
 const symbol = props.symbol
 const currency = props.currency
  const [prices, setPrices] = useState()

  useEffect(() => {
    async function getCoinsPrice() {
      await axios.post(`https://min-api.cryptocompare.com/data/price?fsym=${coin}&tsyms=${currency}`)
        .then((response) => {
          return response.data
        }).then((data) => {
          const newPrice = JSON.stringify(data)
          let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z/gm, '');
          const result = Number(strip).toFixed(2)
          setPrices(result)
        }).catch((error) => {
          console.log(error)
        })
    }
    getCoinsPrice()

  },[coin, prices, currency])

  return( <>
  <CurrencyFormat value={prices} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
  isNumericString={true} thousandSeparator={true} prefix={`${symbol}`} /> </>)
}


const AllCoins = ({ userCoin, userId, currencySymbol, currency }) => {
  const users = userCoin

  const [finalList, setFinalList] = useState()
  
const newCoinList = useCallback((data)=>{
let result = data.filter(o1 => !users[0].some(o2 => o1.full_name === o2.full_name));
setFinalList(result)
},[users])

  useEffect(() => {
      async function getCoins() {
      await axios.post('https://api.profoliomarket.com/user/getAllCoin',
        {}).then((response) => {
          return response.data.data
        }).then((data) => {
          
         data && users && newCoinList(data)
        }).catch((error) => {
          console.log(error)
        })
    }
    getCoins()
   
  }, [users, newCoinList])

  const [modalTitle, setModalTitle] =useState()
  const [coinShort, setCoinShortName] = useState()
  const [coinId, setCoinId] = useState()
  const [modalClick,setModalClick] = useState(false)
const addWallet = (e) => {
  e.preventDefault();
  setModalClick(true)
  axios.post('https://api.profoliomarket.com/user/assignWallet', {userId:userId,coin:coinId})
  .then(() =>{
    window.location.reload()
  }).catch((error) =>{
    console.log(error.response)
  })
}

  const data = finalList
   

  return (
    <>
      {data && <div className={styles.table}>
        <table className={` ${styles.coin_table} table`}>
          <thead>
            <tr>

              <th scope="col">Coin</th>
              <th scope="col">Current Value </th>


              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>

            {data.map((items) => (
              <tr key={items.coin_Id}>
                <td><IconBTN data-bs-toggle="modal" data-bs-target="#addCoin" id={items.wallet_id}>
                  <div className={styles.coin_table_coin} onClick={()=>{setModalTitle(items.full_name);setCoinShortName(items.short_name); setCoinId(items.coin_Id);setModalClick(false);}}>
                    <img className={styles.coin_table_logo} alt={styles.coin_name} src={require(`../../../style/SVG/${items.short_name}.svg`)} />
                    <div className={styles.coin_table_coin_name}>
                      <div className={styles.coin_table_coin_short}>{items.short_name}</div>
                      <div className={styles.coin_table_coin_full}>{items.full_name}</div>
                    </div>
                  </div>
                  </IconBTN>
                </td>
                <td><CoinPrice coinName={items.short_name} symbol={currencySymbol} currency={currency}/></td>
                <td><IconBTN data-bs-toggle="modal" data-bs-target="#addCoin" id={items.wallet_id}><i className="fi fi-rr-add" onClick={()=>{setModalTitle(items.full_name);setCoinShortName(items.short_name); setCoinId(items.coin_Id);setModalClick(false);}}></i></IconBTN></td>
              </tr>
            )
            )}
          </tbody>
        </table>
      </div>}

      <div className="modal fade" id="addCoin" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className= "modal-dialog modal-dialog-centered">
    <div className={`  ${styles.card_inner} modal-content `}>
    <div className={`${styles.card_header} modal-header`}>
        <div className={`  ${styles.card_header_text}`} id="addCoinLabel"> Add {modalTitle}</div>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> 
      <div className={`${styles.card_body} modal-body`}> <FlexColumn>

         {modalClick ?  <LoadingLg><div></div><div></div><div></div><div></div></LoadingLg> :
          <button className={` ${styles.wallet_btn} btn btn-warning btn-rounded`} onClick={addWallet}>Add {coinShort} to Wallet</button>
        }
      </FlexColumn>
      
      </div>
    </div>
  </div>
</div>
    </>
  );
}

export default AllCoins;
import axios from "axios"
import { useEffect, useState } from "react"
import styles from '../../style/main.module.css'
import { convertTime } from "../wallet/components/getTime"
import { BUY, FlexColumn, NotFound, NotFoundIcon, NotFoundText, SELL, Table, Td, Tr, } from "./dashboardComponent"

const TradeHistoryMini = (props) => {
    const userID = props.userId
    const [tradeInfo, setTradeInfo] = useState([])
    const [isEmpty, setIsEmpty] = useState(true)
    //  const buy ={
    //     color:'green',
    //     textTransform: 'uppercase'
    //     }

    const checkType = (item) => { 
         if (item === 'buy') { 
            return (<BUY>BUY </BUY>)
        } 
    else { 
        return (<SELL>SELL </SELL>)
    } 
    }


    useEffect(() => {
        axios.post('https://api.profoliomarket.com/user/getTrades', { userID: userID })
            .then(response => {
                //console.log(response)
                return response.data.data

            }).then(data => {
                if (data.length > 0) {
                    setTradeInfo(data.slice(0, 4))
                    setIsEmpty(false)
                }
                else {
                    setTradeInfo([])
                    setIsEmpty(true)
                }
            }).catch(err => {
                console.log(err.response)
                return err
            })
    }, [userID]);

    const getTimeType = (closingTime) =>{
        if (closingTime === 'pending') {
            return (<div></div>)
        }
        else{
            return (<div>{convertTime(closingTime)}</div>)   
        }
    }

    return (<>
        <div className={styles.table}>
            {isEmpty ? <><NotFound>
                <NotFoundIcon className="fi fi-rr-search-alt">
                </NotFoundIcon>
                <NotFoundText>No Order found</NotFoundText>
            </NotFound></>
                : <Table className={`table`}>
                    <thead>
                        <tr>

                            
                            <th scope="col">Pair</th>
                            <th scope="col">Option</th>
                            <th scope="col">Opened</th>
                            <th scope="col">Closed</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tradeInfo.map(info => (
                            <Tr key={info.tradeId}>
                                <Td><FlexColumn><div>{info.pair}</div></FlexColumn></Td>
                                <Td><FlexColumn>{checkType(info.option)}<div>{info.invested}</div></FlexColumn></Td>
                                <Td><div>{convertTime(info.entryTime)}</div><div>@{info.entryPrice}</div> </Td>
                                <Td><div>{getTimeType(info.closingTime)}</div><div>{info.closingPrice}</div></Td>
                                <Td><div>{info.status}</div></Td>
                            </Tr>

                        ))}
                    </tbody>
                </Table>}
        </div>
    </>);
}

export default TradeHistoryMini;
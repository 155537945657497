
import axios from 'axios';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import Skeleton from '../../market/pages/skeleton/skeleton';
import styles from '../css/index.module.css'
import {  H2,  IndexSection, IndexSectionDescription, IndexSectionWrapper,  Table, Td, Tr } from '../style/bodyStyle';
import IndexExchangeList from './excangeList';

const PairList = () => {
    // const currency = props.currency
    const userFiat = JSON.parse(localStorage.getItem('OMS__CUR'))
    const symbol = userFiat.symbol
    const [isLoading, setIsLoading] = useState(true) 
    const [coinMarket, setCoinMarket]= useState([])

    useEffect(() => {
        const AcutalRate = async () => {
    var  pairData =[]
    const pairs = [{ base: "BTC", pair: "USD" },
    { base: "BNB", pair: "USD" },
    { base: "BUSD", pair: "USD" },
    { base: "ETH", pair: "USD" },
    { base: "LTC", pair: "USD" },
    { base: "USDT", pair: "USD" }];
        for (let k = 0; k < pairs.length; k++) {
            const controller = new AbortController();
       await axios.post(`https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${pairs[k].base}&tsyms=${userFiat.name}`)
        .then(response =>{
            return response.data.RAW
        })
        .then(data=>{
          //  console.log(data)
            var item = JSON.stringify(data)
            if (String(pairs[k].base).length < 4){
                item = item.substring(14);
                const pairInfo =JSON.parse(item.slice(0, item.length - 2))
                const newPrice = JSON.stringify(pairInfo.PRICE)
                let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z|,/gm, '');
               const result = Number(strip).toFixed(2)
               pairData.push({"price": result, "change": Number(pairInfo.CHANGEPCT24HOUR).toFixed(2), "base": pairs[k].base, "cross":userFiat.name,  "symbol": userFiat.symbol, "marketCap": pairInfo.MKTCAP} )
               
                

           }
           else if (String(pairs[k].base).length === 4){
            item = item.substring(15);
            const pairInfo =JSON.parse(item.slice(0, item.length - 2))
            const newPrice = JSON.stringify(pairInfo.PRICE)
                let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z|,/gm, '');
               const result = Number(strip).toFixed(2)
                pairData.push({"price": result, "change":  Number(pairInfo.CHANGEPCT24HOUR).toFixed(2), "base": pairs[k].base, "cross":userFiat.name,  "symbol": userFiat.symbol,  "marketCap": pairInfo.MKTCAP} )

       }
        })
        controller.abort()
    }
   

   setCoinMarket(pairData)
    localStorage.setItem('__cList', JSON.stringify(pairData))
    // console.log(pairData)
    setIsLoading(false)
}

AcutalRate()
window.setInterval(() => {
            AcutalRate()
            }, 30000)
      },[symbol])

    return (<>
    {isLoading ? <Skeleton type="PairList"/> : coinMarket && <IndexExchangeList List={coinMarket} /> } 

    </>);
}
const CoinPrice = ({coinName}) => {
    const userFiat = JSON.parse(localStorage.getItem('OMS__CUR'))
    const symbol = userFiat.symbol
    const currency = userFiat.name
     const [coinMarket, setCoinMarket] = useState([])
  
    useEffect(() => {
        const InfoRate =  () => {
         axios.post(`https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${coinName}&tsyms=${currency}`)
          .then((response) => {
            return response.data.RAW
          }).then((data) => {
            console.log(data)
            var item = JSON.stringify(data)
            if (String(coinName).length < 4) {
                item = item.substring(14);
                setCoinMarket(JSON.parse(item.slice(0, item.length - 2)))
               //console.log(JSON.parse(item.slice(0, item.length - 2)) )
            }
            else if (String(coinName).length === 4) {
                item = item.substring(15);
                setCoinMarket(JSON.parse(item.slice(0, item.length - 2)))
                // console.log(JSON.parse(item.slice(0, item.length - 2)) )
            }
          
          }).catch((error) => {
            console.log(error)
          })
        }
        InfoRate()
        window.setInterval(() => {
            InfoRate()
            }, 30000)
        
    },[coinName, currency])
    
    const getChange = (change) => {
        if (change < 0){
            return (<> <div data-bn-type="text" className={` ${styles.sell} ${styles.coin_changes}`} style={{ direction: "ltr" }}>{change.toFixed(2)}%</div></>)
        }
        else if (change > 0){
            return (<> <div data-bn-type="text" className={` ${styles.buy} ${styles.coin_changes}`} style={{ direction: "ltr" }}>+{change.toFixed(2)}%</div></>)
        }
        else{
            return (<> <div data-bn-type="text" className={` ${styles.coin_changes}`} style={{ direction: "ltr" }}>{change}%</div></>)
        }
       
    }

    return( <>
     <Td>
           <CurrencyFormat value={coinMarket.PRICE} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
    isNumericString={true} thousandSeparator={true} prefix={`${symbol}`} />
        </Td>
        <Td>{getChange(coinMarket.CHANGEPCT24HOUR)}</Td>
        <Td>  {coinMarket.VOLUME24HOUR}
    </Td>
        
        <Td><CurrencyFormat value={coinMarket.HIGH24HOUR} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
    isNumericString={true} thousandSeparator={true} prefix={`${symbol}`} /></Td>
        <Td><CurrencyFormat value={coinMarket.LOW24HOUR} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
    isNumericString={true} thousandSeparator={true} prefix={`${symbol}`} /></Td>
    <Td><CurrencyFormat value={coinMarket.MKTCAP} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
    isNumericString={true} thousandSeparator={true} prefix={`${symbol}`} /></Td>
    </>)
  }



const IndexMarket = () => {
    const [coinList, setCoinList] = useState()
    const [infoLoading, setInfoLoading] = useState(true)

    useEffect(() => {
     
         axios.post('https://api.profoliomarket.com/user/getAllCoin',
          {}).then((response) => {
            return response.data.data
          }).then((data) => {
         
            setCoinList(data)
            setInfoLoading(false)
  
          }).catch((error) => {
            console.log(error)
          })
    
  
    }, [])
    return (<>
    
     <div className={styles.main_container}> 
     <IndexSection>
                <H2>
                    Markets
                </H2>
                <IndexSectionDescription>
                Highlight Coin
                </IndexSectionDescription>
                <div className={styles.index_table}>
                    <PairList />
                </div>
               
            </IndexSection>
     </div>
     <div className={`${styles.card} ${styles.lg}`}>
                <div className={styles.card_inner}>
                    <div className={styles.card_content}>
                          <div className={styles.table}>{infoLoading ? <></> :
                          
                         
                     <Table className={`table`}>
                     <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">24h Change</th>
                            <th scope="col">24h Volume</th>
                            <th scope="col">24h High</th>
                            <th scope="col">24h Low</th>
                            <th scope="col">Market Cap</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                    
                        {coinList && coinList.map((item) =>(<>
                        <Tr key={item.id}>
                        <Td>
            <div className={styles.coin_table_coin}>
            <img className={styles.coin_table_logo} alt={styles.coin_name}  src={ require(`../../market/style/SVG/${item.short_name}.svg`) }  />
                <div className={styles.coin_table_coin_name}> 
                <div className={styles.coin_table_coin_short}>{item.short_name}</div> 
                <div className={styles.coin_table_coin_full}>{item.full_name}</div>
                </div>
             </div> 
        </Td>
        
           <CoinPrice coinName={item.short_name}/>
        
       
                        </Tr>
                        </>))}
                        
                        </tbody>
                     </Table>
}
                    </div>
                        </div></div></div>
    </> );
}
 
export default IndexMarket;
import axios from "axios";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import CurrencyFormat from "react-currency-format";
import { QRCode } from "react-qrcode-logo";
import { useParams } from "react-router-dom";
import styles from '../../style/main.module.css'
import { NotFound, NotFoundIcon, NotFoundText, Table, Td, Tr } from "../dashboard/dashboardComponent";
import { AuthTextbox, AuthTextFlex, AuthTextgroup, CardContent, Clearfix, CoinBlock, CoinImage, CoinInitial, CoinName, FailedText, FlexColumn, FlexRow, IconBody, IconDiv, RowColumn, SuccessText, TableAmountDeposit, TableAmountWithdraw, TextCopied, TextList, TextListChild, TextListIcon, TextListItem, WarningText, YellowButton } from "./components/bodyStyle";
import { convertTime } from "./components/getTime";
import RecieveCoin from "./components/recieve";
import SwapCoin from "./components/swap";
import DepositCoinSingle from "./spot/depositSingle";
import WithdrawSingle from "./spot/withdrawSingle";
const CoinHistory = ({ data }) => {
    const checkTransType = (type, amount) => {
        if (type.toLowerCase() === "deposit" || type.toLowerCase() === "swap") { return (<><TableAmountDeposit>+{amount}</TableAmountDeposit></>) }
        else if (type.toLowerCase() === "withdraw") { return (<><TableAmountWithdraw>{amount}</TableAmountWithdraw></>) }
    }
    const getStatus = (status) => {
        if (status.toLowerCase() === "pending") { return (<><WarningText>Pending</WarningText></>) }
        else if (status.toLowerCase() === "processed") { return (<><SuccessText>Successful</SuccessText></>) }
        else if (status.toLowerCase() === "failed") { return (<><FailedText>Failed</FailedText></>) }
    }
    return (<>
        <div className={styles.table}>
            <Table className={` ${styles.coin_table} table`}>

                <tbody>
                    {data.map((items) => (
                        <Tr key={items.transId}>
                            <Td><FlexColumn><div className={styles.coin_table_coin_short}>{items.transType}</div>
                                <div className={styles.coin_table_coin_full}>{convertTime(items.date)}</div></FlexColumn></Td>
                            <Td>
                                <FlexColumn>{checkTransType(items.transType, items.amount)}
                            <div className={styles.coin_table_coin_full}>{getStatus(items.status)}</div>
                            </FlexColumn>
                            </Td>
                        </Tr>
                    ))}


                </tbody>
            </Table>
        </div>
    </>)
}
const CoinSingle = () => {
    const params = useParams()
    const userId = params.userId
    const walletId = params.walletId
    const [isEmpty, setIsEmpty] = useState(true)

    const [coinName, setCoinName] = useState()
    const [coinInitial, setCoinInitial] = useState()
    const [coinBalance, setCoinBalance] = useState()
    const [coinPending, setCoinPending] = useState()
    const [walletAddress, setWalletAddress] = useState()
    const [coinTransactions, setCoinTransactions] = useState([])
    const [coinMarket, setCoinMarket] = useState([])
    const currSymbol = JSON.parse(localStorage.getItem("OMS__CUR")).symbol
    document.title = coinName
    useEffect(() => {
        async function getCoins() {
            const controller = new AbortController();
            await axios.post('https://api.profoliomarket.com/user/singleCoin',
                { userID: userId, walletID: walletId }).then((response) => {
                    return response.data
                }).then((data) => {
                    const coinInfo = data.coinInfo
                    // const coinTrans = data.transaction
                    setCoinName(coinInfo.full_name)
                    setCoinInitial(coinInfo.short_name)
                    //setCoinTransactions(coinTrans)
                    setCoinBalance(coinInfo.balance)
                    setCoinPending(coinInfo.pending)
                    setWalletAddress(coinInfo.walletAddress)


                    // setCoinList(data)

                }).catch((error) => {
                    console.log(error)
                })
                controller.abort()
        }
        getCoins()

    }, [walletId, userId])

    useEffect(() => {
        const getTransactionHistory = async () => {
            const controller = new AbortController();
            await axios.post('https://api.profoliomarket.com/user/userTransactionById', { userId: userId, walletId: walletId })
                .then(response => {
                    return response.data.data
                }).then(data => {
                    if (data.length > 0) {
                        setCoinTransactions(data.slice(0, 10))

                        setIsEmpty(false)
                    }

                })
                .catch(error => {
                    console.log(error)
                })
            controller.abort()
        }
        getTransactionHistory()
        setInterval(() => {getTransactionHistory()}, 30000)
    }, [userId, walletId])

    useEffect(() => {
        const userFiat = JSON.parse(localStorage.getItem('OMS__CUR'))
        const controller = new AbortController();
      const priceData =()=>  axios.post(`https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${coinInitial}&tsyms=${userFiat.name}`)
            .then(response => {
                return response.data.RAW
            })
            .then(data => {
                //console.log(data)
                var item = JSON.stringify(data)
                if (String(coinInitial).length < 4) {
                    item = item.substring(14);
                    setCoinMarket(JSON.parse(item.slice(0, item.length - 2)))
                   //console.log(JSON.parse(item.slice(0, item.length - 2)) )
                }
                else if (String(coinInitial).length === 4) {
                    item = item.substring(15);
                    setCoinMarket(JSON.parse(item.slice(0, item.length - 2)))
                    // console.log(JSON.parse(item.slice(0, item.length - 2)) )
                }
            })
            controller.abort()
            priceData()
            setInterval(() => {priceData()}, 30000)

    }, [coinInitial])


    const getChange = (change) => {
        if (change < 0) {
            return (<> <div data-bn-type="text" className={` ${styles.sell} ${styles.coin_changes}`} style={{ direction: "ltr" }}>{change}%</div></>)
        }
        else if (change > 0) {
            return (<> <div data-bn-type="text" className={` ${styles.buy} ${styles.coin_changes}`} style={{ direction: "ltr" }}>+{change}%</div></>)
        }
        else {
            return (<> <div data-bn-type="text" className={` ${styles.coin_changes}`} style={{ direction: "ltr" }}>{change}%</div></>)
        }

    }
    //const data = coinTransactions
    const [copiedShow, setCopiedShow] = useState(false)

    const hideState = ""
    const showState = <TextCopied>Copied</TextCopied>
    const toggleCopyAddress = () => {
        setCopiedShow(true);
        showAlert()
    };

const showAlert = () => {
    setTimeout(() => {
        setCopiedShow(false);
    }, 2000);
}

    const [modalInfo, setModalInfo] = useState()
    const [modalType, setModalType] = useState()
    const OpenModal = (e) =>{
        e.preventDefault()
        const modalType = e.target.id
        setModalType(modalType)
        if (modalType === 'Deposit') {
            setModalInfo(<><DepositCoinSingle coinShortName={coinInitial} coinFullName={coinName} walletAddress={walletAddress} walletId={walletId}/></>)
          }
          else if (modalType === 'Withdrawal') {

            setModalInfo(<><WithdrawSingle coinShortName={coinInitial} coinFullName={coinName} walletAddress={walletAddress} walletId={walletId} balance={coinBalance}/></>)
          }
    }
    return (<>
        <div className={styles.main_container}>
            <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>

                        <div className={` ${styles.heading_text}`}>

                            <FlexRow>
                                {coinInitial && <CoinImage alt={styles.coin_name} src={require(`../../style/SVG/${coinInitial}.svg`)} />}
                                <CoinBlock>
                                    <CoinName>{coinName}</CoinName>
                                    <CoinInitial>{coinInitial}</CoinInitial>
                                </CoinBlock>
                            </FlexRow>




                        </div>

                    </div>
                </div>
            </div>
            <div className={styles.dashboard_table}>
                <div className={`${styles.card} ${styles.sm}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={styles.coin_title}>{coinName}</div>
                                    {getChange(Number(coinMarket.CHANGEPCT24HOUR).toFixed(2))}
                                </div>
                            </div>
                          
                            <div className={styles.card_body}>
                                <div id="coin_1" className={styles.coin_ad}>
                                    <div data-bn-type="text" className={styles.coin_price}>
                                       
                                        <CurrencyFormat value={coinMarket.PRICE} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={currSymbol} />
                                    </div>
                                    <FlexRow>
                                <div className={styles.card_body}>
                            <div id="coin_1" className={styles.coin_ad}>
                            <div className={styles.coin_Market_cap_wrapper}>
                                        <div className={styles.coin_Market_cap}>Open</div>
                                    <div data-bn-type="text" className={`${styles.coin_equivalent} ${styles.coin_price}`}>
                                    <CurrencyFormat value={coinMarket.OPENDAY} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={currSymbol} /> 
                                    </div>
                                    </div>
                                </div>

                            </div> 
                            <div className={styles.card_body}>
                            <div id="coin_1" className={styles.coin_ad}>
                            <div className={styles.coin_Market_cap_wrapper}>
                                        <div className={styles.coin_Market_cap}>High</div>
                                    <div data-bn-type="text" className={`${styles.coin_equivalent} ${styles.coin_price}`}>
                                    <CurrencyFormat value={coinMarket.HIGHDAY} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={currSymbol} /> 
                                    </div>
                                    </div>
                                </div>

                            </div> 
                            <div className={styles.card_body}>
                            <div id="coin_1" className={styles.coin_ad}>
                            <div className={styles.coin_Market_cap_wrapper}>
                                        <div className={styles.coin_Market_cap}>Low</div>
                                    <div data-bn-type="text" className={`${styles.coin_equivalent} ${styles.coin_price}`}>
                                    <CurrencyFormat value={coinMarket.LOWDAY} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={currSymbol} /> 
                                    </div>
                                    </div>
                                </div>

                            </div> 
                            
                            </FlexRow>
                                <div className={styles.coin_Market_cap_wrapper}>
                                        <div className={styles.coin_Market_cap}>Market Cap</div>
                                    <div data-bn-type="text" className={styles.coin_equivalent}>
                                    <CurrencyFormat value={coinMarket.MKTCAP} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={currSymbol} /> 
                                    </div>
                                    </div>
                                    <div className={styles.coin_Market_cap_wrapper}>
                                        <div className={styles.coin_Market_cap}>Direct Volume</div>
                                    <div data-bn-type="text" className={styles.coin_equivalent}>
                                    <CurrencyFormat value={coinMarket.TOPTIERVOLUME24HOURTO} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={currSymbol} /> 
                                    </div>
                                    </div>

                           
                                    
                                </div>
                            </div>
                          
                           
                        </div>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.sm}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={` ${styles.balance} ${styles.coin_title} h5`}>Wallet Details</div>
                                </div>
                            </div>
                            <div className={styles.card_body}>
                                <CardContent>
                                    <FlexRow>
                                        <FlexColumn>
                                            <TextList>
                                                <TextListIcon>
                                                    <i className={`fi fi-sr-money ${styles.balance}`}></i>
                                                </TextListIcon>
                                                <FlexColumn>
                                                    <TextListItem>Current Balance</TextListItem>
                                                    <TextListChild>{Number(coinBalance).toFixed(8)}{coinInitial}</TextListChild>
                                                </FlexColumn>

                                            </TextList>
                                            <TextList>
                                                <TextListIcon>
                                                    <i className={` fi fi-sr-hourglass-end ${styles.balance}`}></i>
                                                </TextListIcon>
                                                <FlexColumn>
                                                    <TextListItem>Pending Balance</TextListItem>
                                                    <TextListChild>{Number(coinPending).toFixed(8)}{coinInitial}</TextListChild>
                                                </FlexColumn>


                                            </TextList>


                                        </FlexColumn>
                                        <FlexColumn style={{ alignItems: 'center' }}>
                                            <QRCode value={`${walletAddress}`} size={90} level={'M'}
                                                eyeRadius={[[10, 10, 0, 10], [10, 10, 10, 0], [10, 0, 10, 10],]}
                                                qrStyle={'squares'} style={{ borderRadius: '10px' }} className="rounded" />
                                            <TextListChild>
                                                <AuthTextgroup className="mb-4" style={{ marginTop: '10px' }}>
                                                {copiedShow ? showState : hideState}
                                                    <AuthTextFlex>
                                                        <input type="radio" className="text" value={walletAddress} style={{ display: "none" }} />
                                                        <AuthTextbox placeholder={walletAddress} type="text" value="" disabled={true} />
                                                        <IconDiv className="icon-div">
                                                            <CopyToClipboard text={walletAddress}
                                                                onCopy={() => toggleCopyAddress}>
                                                                <IconBody onClick={toggleCopyAddress}><i className="fi fi-sr-copy-alt"></i></IconBody>
                                                            </CopyToClipboard>

                                                        </IconDiv>
                                                    </AuthTextFlex>
                                                </AuthTextgroup>
                                            </TextListChild>



                                        </FlexColumn>
                                    </FlexRow>

                                    <Clearfix />
                                    <RowColumn>
                                   
                                        <YellowButton data-bs-toggle="modal" data-bs-target="#fundAccountModal" id="Deposit" onClick={OpenModal}>Deposit</YellowButton>
                                        <YellowButton data-bs-toggle="modal" data-bs-target="#fundAccountModal" id="Withdrawal" onClick={OpenModal}>Withdraw</YellowButton>
                                    </RowColumn>




                                    {/* <AccountBalance id={userId} currency={currencySymbols} fiat={currency} /> */}
                                </CardContent>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.sm}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={` ${styles.balance} ${styles.coin_title} h5`}>Wallet History</div>
                                </div>
                            </div>
                            <div className={styles.card_body}>
                                {isEmpty ? <><NotFound>
                                    <NotFoundIcon className="fi fi-rr-search-alt">
                                    </NotFoundIcon>
                                    <NotFoundText>No Transaction found</NotFoundText>
                                </NotFound></> : coinTransactions && <CoinHistory data={coinTransactions} />}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className="modal fade" id="fundAccountModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className= "modal-dialog modal-dialog-centered modal-md">
    <div className={`  ${styles.card_inner} modal-content `}>
    <div className={`${styles.card_header} modal-header`}>
        <div className={`  ${styles.card_header_text}`} id="fundAccountModalLabel">{modalType} Coin</div>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> 
      <div className={`${styles.card_body} modal-body`}>
          {
             modalInfo
               
          }
      
      </div>
    </div>
  </div>
</div>
    </>);
}

export default CoinSingle;
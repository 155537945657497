import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../style/main.module.css'
import UserCoins from './components/myCoins';
import AccountBalance from './components/mainBalance';
import React  from 'react';
import CoinMenu from './coinMenu';

const Wallet = () => {

    const [currency, setCurrency] = useState()
    const [currencySymbols, setCurrencySymbols] = useState()
    const [userId, setUserId] = useState()
   
    const [AccountFiatShown, setAccountFiatShown] = useState(false);
    useEffect(()=>{

    const userInfo = localStorage.getItem('_currentInfo')
    const users = JSON.parse(userInfo)
        const userCurrency = localStorage.getItem('OMS__CUR')
           const curr = JSON.parse(userCurrency)
      setCurrency(curr.name)
      setCurrencySymbols(curr.symbol)
      setUserId(users.user_id) 
        },[])

      
        const hideFiatState = <i className="fi fi-sr-eye"></i>
        const showFiatState = <i className="fi fi-sr-eye-crossed"></i>
        const toggleFiatAccount = () => {
            setAccountFiatShown(!AccountFiatShown);
          };
    return ( <> 
    <div className={styles.main_container}>
    <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>
                        <div className={`h2 ${styles.heading_text}`}>
                             Wallet Details
                        </div>
                    </div> 
                </div>
    </div>
     <div className={styles.dashboard_table}>
            <div className={`${styles.card} ${styles.md}`}>
                <div className={styles.card_inner}>
                    <div className={styles.card_content}>
                       <div className={styles.card_header}>
                       <div className={styles.coin_id}>
                            <div className={` ${styles.balance} ${styles.coin_title} h5`}>Total Balance</div> 
                            <div className={`  ${styles.coin_changes}`} style={{ direction: "ltr", lineHeight: "2" }} onClick={toggleFiatAccount}>{AccountFiatShown ? hideFiatState : showFiatState } </div> 
                            </div>
                            </div> 
                            <div className={styles.card_body}>
                                <div className={styles.balance_body}>
                                {AccountFiatShown ? <> <div className={styles.main_balance}>****** *****</div></> :    <AccountBalance id={userId} currency={currencySymbols} fiat={currency} />}

                          
                            </div>
                            </div>
                            <div className={styles.card_header}>
                            <div className={styles.card_header_text}>All Coin</div> 
                            <Link className={styles.note_last} to="spot">
                                <div className={styles.note_last_item}>Add Coin</div>
                                <div className={styles.note_last_item_icon}> 
                                <i className="fi fi-rr-add"></i>
                                </div></Link>
                            </div>
                            

                            <div className={styles.card_body}>
                                {userId && <UserCoins userId={userId} />}
                            </div>
                    </div>
                </div>
            </div>
            <CoinMenu/>
    </div>
    </div>

    
    </> );
}
 
export default Wallet;
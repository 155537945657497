import { CardContent, Clearfix, FlexColumn, IconWrapper, IconWrapperNot, NotFound, NotFoundIcon, NotFoundText, Table, Td, TextList, TextListIcon, TextListItem, Tr } from "./style";
import styles from '../../style/main.module.css'
import SettingsModals from "./modal";
import { useEffect, useState } from "react";
import axios from "axios";
import { convertTime } from "../wallet/components/getTime"

export const ActivityLogs = ({userId}) => {
  const [activityLog, setActivityLog] = useState([]);
    const [isEmpty, setIsEmpty] = useState(true);
useEffect(() => {
        axios.post('https://api.profoliomarket.com/user/getActivityLog',{userId:userId})
    .then(response =>{
        return response.data.data
    }
    ).then(data =>{
        if(data.length === 0){
            setIsEmpty(true)
        }
        else{
            setActivityLog(data.slice(0,20))
            setIsEmpty(false)
        }
    })   
},[userId])
   return (<>
    <div className={styles.table}>
        {isEmpty ? <><NotFound>
            <NotFoundIcon className="fi fi-rr-search-alt">
            </NotFoundIcon>
            <NotFoundText>No log found</NotFoundText>
        </NotFound></>
            : <Table className={`table`}>
                <thead>
                    <tr>

                        
                        <th scope="col">Date</th>
                        <th scope="col">Ip Address</th>
                        <th scope="col">Location</th>
                    </tr>
                </thead>
                <tbody>
                    {activityLog.map(info => (
                        <Tr key={info.id}>
                            
                            <Td><div>{convertTime(info.time)}</div></Td>
                            <Td><div>{info.ipAddress}</div></Td>
                            <Td><div>{info.location}</div></Td>
                        </Tr>

                    ))}
                </tbody>
            </Table>}
    </div>
</>);
}
const Settings = () => {
    const [phoneVerified, setPhoneVerified] = useState(false)
    useEffect(() => {
        const userInfo = localStorage.getItem('_currentInfo')
        const users = JSON.parse(userInfo)
        if (users.verificationStatus === "0") {
            setVerified('Invalid user')
        } else if (users.verificationStatus === "1") {
            setVerified('Unverified')
        } else if (users.verificationStatus === "2" ) {
            setVerified('Under Review')
        } else if (users.verificationStatus === "3" ) {
            setVerified('Verified')
        }
        if (users.phoneVerified < 1) {
            setPhoneVerified(false)
        }
        else {
            setPhoneVerified(true)
        }
    }, [phoneVerified])


    const [verified, setVerified] = useState()
    const [modalType, setModalType] = useState()
    const userInfo = JSON.parse(localStorage.getItem('_currentInfo'));
    const email = userInfo.email;
    const phone = userInfo.phone;
    const userId = userInfo.user_id;
    
    return (<>
        <div className={styles.main_container}>
            <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>
                        <div className={`h2 ${styles.heading_text}`}>
                            Settings/Security
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.dashboard_table}>
                <div className={`${styles.card} ${styles.lg}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={` ${styles.balance} ${styles.coin_title} h5`}>User Verification</div>
                                    {verified === "Verified" ? <>
                            <div className={styles.dashboard_welcome_verification} style={{backgroundColor: 'var(--green)'}}>
                               <i className="fi fi-rs-check-circle"></i>{verified}</div>
                              </>: <>
                              <div className={styles.dashboard_welcome_verification} >
                               <i className="fi fi-rs-document"></i>{verified}</div>
                              </>}  
                                </div>
                            </div>
                            <div className={styles.card_body}>
                                <CardContent>

                                    <FlexColumn>
                                        <TextList>
                                            <TextListIcon>
                                                <i className={` fi fi-sr-at`}></i>
                                            </TextListIcon>
                                            <TextListItem>{email}</TextListItem>
                                            <IconWrapper >
                                                <i className="fi fi-sr-checkbox"></i> Verified
                                            </IconWrapper>

                                        </TextList>
                                        <TextList>
                                            <TextListIcon>
                                                <i className={` fi fi-sr-phone-call`}></i>
                                            </TextListIcon>
                                            <TextListItem>{phone}</TextListItem>
                                            {phoneVerified ?
                                                <IconWrapper >
                                                    <i className="fi fi-sr-checkbox"></i> Verified
                                                </IconWrapper> :
                                                <IconWrapperNot data-bs-toggle="modal" data-bs-target="#modalUsage" onClick={(e) => { setModalType("verifiyPhone"); }}>
                                                    <i className="fi fi-sr-cross-circle"></i> Verify Phone
                                                </IconWrapperNot>}
                                        </TextList>
                                        <TextList>
                                            <TextListIcon>
                                                <i className={`fi fi-sr-fingerprint`}></i>
                                            </TextListIcon>
                                            <TextListItem>Transactions 2FA</TextListItem>
                                            <IconWrapper >
                                                OTP
                                            </IconWrapper>
                                        </TextList>
                                        <TextList>
                                            <TextListIcon>
                                                <i className={`fi fi-sr-unlock`}></i>
                                            </TextListIcon>

                                            <TextListItem>Account PIN</TextListItem>
                                            <IconWrapper >
                                                Not Set
                                            </IconWrapper>
                                        </TextList>
                                        <TextList>
                                            <TextListIcon>
                                                <i className={`fi fi-sr-key`}></i>
                                            </TextListIcon>

                                            <TextListItem>Account Password</TextListItem>
                                            <IconWrapper >
                                                <a href={`https://auth.profoliomarket.com/forgot.html?id=${userId}`} target="_blank" rel="noreferrer">   Change Password </a>
                                              
                                            </IconWrapper>
                                        </TextList>
                                        <TextList>
                                            <TextListIcon>
                                                <i className={`fi fi-sr-apps`}></i>
                                            </TextListIcon>

                                            <TextListItem>App Version</TextListItem>
                                            <IconWrapper >
                                                v{global.appVersion}
                                            </IconWrapper>
                                        </TextList>
                                    </FlexColumn>
                                    <Clearfix />
                                    {/* <AccountBalance id={userId} currency={currencySymbols} fiat={currency} /> */}
                                </CardContent>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.lg}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={` ${styles.balance} ${styles.coin_title} h5`}>Activity Logs</div>
                                </div>
                                </div>
                                <div className={styles.card_body}>
                                   {userId &&  <ActivityLogs userId={userId} />}
                                </div>
                            
                        </div></div>

                </div>
            </div>
        </div>
        <SettingsModals type={modalType} phone={phone} userId={userId} />
    </>);
}

export default Settings;
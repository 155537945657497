import styles from '../style/main.module.css';
import wall from '../style/new.module.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import React from 'react';
import Cookies from 'js-cookie';
import { convertTime } from './wallet/components/getTime';
import PairList from './dashboard/pairList';
import NewsMini from './dashboard/newsMini';
import TradeHistoryMini from './dashboard/tradeHistoryMini';
import WalletConnetMini from './dashboard/connectWallet';
import axios from 'axios';
import AccountBalance from './wallet/components/mainBalance';
import GoalMeter from './dashboard/dashboardGoalMeter';

const UserDashboard = () => {


    const [firstName, setFirstName] = useState()
    const [lastName, setLastNamae] = useState()

    const [currency, setCurrency] = useState()
    const [currencySymbols, setCurrencySymbols] = useState()
    const [lastLogin, setLastLogin] = useState()
    const [verified, setVerified] = useState()
    const [userId, setUserId] = useState()
    useEffect(() => {
        const check = Cookies.get('__isLoggedIn')
        if (check) {
            const userInfo = localStorage.getItem('_currentInfo')
            const users = JSON.parse(userInfo)
            const userCurrency = localStorage.getItem('OMS__CUR')
            const curr = JSON.parse(userCurrency)
            setFirstName(users.firstName)
            setLastNamae(users.lastName)
            setCurrency(curr.name)
            setCurrencySymbols(curr.symbol)
            setUserId(users.user_id)
            setLastLogin(convertTime(users.lastLog))
            if (users.verificationStatus === "0") {
                setVerified('Invalid user')
            } else if (users.verificationStatus === "1") {
                setVerified('Unverified')
            } else if (users.verificationStatus === "2") {
                setVerified('Under Review')
            } else if (users.verificationStatus === "3") {
                setVerified('Verified')
            }
        }
        else {

            //navigate('/login') 
            //console.log(response)
        }

    }, [])

    useEffect(() => {
        const getCurrency = async () => {
            await axios.post('https://api.profoliomarket.com/user/getOtherBalance', { userId: userId })
                .then(response => {
                    return response.data.data

                }).then((data) => {

                    if (data !== undefined) {
                        const balance = data.fiat
                        setAccountBalance(balance)
                    }


                    //setBonusBalance(data.bonus)
                    //  setTotalTrades(data.length)
                })
        }
        getCurrency()
    }, [userId]);

    const [accountBalance, setAccountBalance] = useState()
    // const [bonus, setBonusBalance] = useState()

    return (<>

        <div className={styles.main_container}>
            <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>
                        <div className={styles.dashboard_welcome_name}>Hello {lastName} {firstName}
                           {verified === "Verified" ? <>
                            <Link className={styles.dashboard_welcome_verification} to="#" style={{backgroundColor: 'var(--green)'}}>
                               <i className="fi fi-rs-check-circle"></i>{verified}</Link>
                              </>: <>
                              <Link className={styles.dashboard_welcome_verification} to="#">
                               <i className="fi fi-rs-document"></i>{verified}</Link>
                              </>}  
                              
                        </div>
                        <div className={styles.dashboard_welcome_last_login}>
                            Last logged in on {lastLogin}</div>
                    </div>
                </div>
            </div>

            <div className={wall.p_5}>
              <div className={`${wall.flex} ${wall.flex_wrap} ${wall.justify_around}`}>
                <div className={`${wall.well} ${wall.balance_card} ${wall.flex} ${wall.flex_row} ${wall.justify_between}`}>
                    <div className={`${wall.flex} ${wall.flex_col} ${wall.justify_between}`}>
                        <div className={wall.amount_label}>
                        Account Balance
                        </div>
                        <div className={wall.amount}>
                        <CurrencyFormat value={accountBalance} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                                        isNumericString={true} thousandSeparator={true} prefix={`${currencySymbols}`} />         
                                         <Link to="#" className={styles.dashboard_welcome_balance_currency}>{currency}</Link>  </div>
                    </div>
                    <div className={`${wall.flex} ${wall.flex_col} ${wall.justify_between} ${wall.items_end}`}>
                        <div className={wall.display_option}>
                            <i className="fi fi-rr-eye-crossed"></i>
                        </div>
                        <div className={wall.mt_2}>
                        <Link to={'/home/wallet/spot'} className={`btn ${wall.primary_btn}`}>View Details</Link>
                           
                        </div>

                    </div>
                </div>
                <div className={`${wall.well} ${wall.balance_card} ${wall.flex} ${wall.flex_row} ${wall.justify_between}`}>
                    <div className={`${wall.flex} ${wall.flex_col}`}>
                        <div className={`${wall.amount_label} ${wall.text_white}`}>
                           Wallet Balance
                        </div>
                        <div className={wall.amount}>
                        <AccountBalance id={userId} currency={currencySymbols} fiat={currency} />          </div>
                    </div>
                    <div className={`${wall.flex} ${wall.flex_col} ${wall.justify_between} ${wall.items_end}`}>
                        <div className={wall.display_option}>
                            <i className="fi fi-rr-eye-crossed"></i>
                        </div>
                        <div className="mt-2">
                            <Link to={'/home/wallet'} className={`btn ${wall.secondary_btn}`}>Open Wallet</Link>
                        </div>
                    </div>
                </div>
            </div>  
            </div>
            <div className={styles.dashboard_table}>
            {currency && <PairList currency={currency} currencySym={currencySymbols} />}
            </div>
            {currency && <GoalMeter userId={userId} currency={currency}  currencySymbols={currencySymbols} balance={accountBalance}/>}

            {/* <div className={`${styles.dashboard_banner}`}>
                <div className={` ${styles.dashboard_banner_inner}`}>
                    <div className={` ${styles.dashboard_banner_content}`}>
                        <div className={styles.dashboard_welcome_balance_logo_wrapper}>
                            <div className={styles.dashboard_welcome_balance_logo_inner}>
                                <i className={`fi fi-rr-money ${styles.dashboard_welcome_balance_logo}`}></i>
                            </div>
                            <span>
                                <div className={styles.dashboard_welcome_balance_text_header}> Account Balance</div>
                                <div className={styles.dashboard_welcome_balance_text}>
                                    <CurrencyFormat value={accountBalance} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                                        isNumericString={true} thousandSeparator={true} prefix={`${currencySymbols}`} />
                                    <Link to="#" className={styles.dashboard_welcome_balance_currency}>{currency}</Link>
                                </div>
                            </span>
                        </div>

                    </div>
                </div>
            </div> */}
            <div className={styles.dashboard_table}>
                
                <div className={`${styles.card} ${styles.md}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.card_header_text}>Recent Orders</div>
                                <Link className={styles.note_last} to="/home/order">
                                    <div className={styles.note_last_item}>View All</div>
                                    <div className={styles.note_last_item_icon}>
                                        <i className="fi fi-rr-angle-circle-right"></i>
                                    </div></Link>
                            </div>
                            <div className={styles.card_body}> {userId && <TradeHistoryMini userId={userId} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.sm}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.card_header_text}>Top Insights</div>
                                <Link className={styles.note_last} to="/home/blog">
                                    <div className={styles.note_last_item}>View All</div>
                                    <div className={styles.note_last_item_icon}>
                                        <i className="fi fi-rr-angle-circle-right"></i>
                                    </div></Link>
                            </div>
                            <div className={styles.card_body}>
                                <NewsMini />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.md}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.card_header_text}>Link to External Wallet</div>
                                <Link className={styles.note_last} to="/home/connectWallect">
                                    <div className={styles.note_last_item}>Connect Wallet</div>
                                    <div className={styles.note_last_item_icon}>
                                        <i className="fi fi-rr-angle-circle-right"></i>
                                    </div></Link>
                            </div>
                            <div className={styles.card_body}>
                                <WalletConnetMini id={userId} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.sm}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.card_header_text}>Activities</div>
                            </div>
                            <div className={styles.card_body}> </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </>);
}

export default UserDashboard;
import axios from 'axios';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import styles from '../../../style/main.module.css'
import DepositFiat from './depositFiat';
import { CurrencyWrapper, FlexRow } from './style';
import WithdrawFiat from './withdrawFiat';
const FiatWallet = ({showFiat}) => {
    const user = JSON.parse(localStorage.getItem('_currentInfo'));
    const userCurrency = JSON.parse(localStorage.getItem('OMS__CUR'));
    const userId = user.user_id;
    const fiatCurrency = userCurrency.symbol;
    useEffect(() => {
        const getbalance = async () => {
        await axios.post('https://api.profoliomarket.com/user/getOtherBalance', { userId: userId })
            .then(response => {
                // console.log(response)
                return response.data.data

            }).then((data) => {
                setAccountBalance(data.fiat)

                //setBonusBalance(data.bonus)
                //  setTotalTrades(data.length)
            })}
        getbalance()
        setInterval(() => {getbalance()}, 30000)
    }, [userId]);
    const OpenModal = (e) =>{
        e.preventDefault()
        const modalType = e.target.id
        setModalType(modalType)
        if (modalType === 'Deposit') {
            setModalHeader(<>{modalType} from Wallet</>)
            setModalInfo(<><DepositFiat /></>)
           }
           else if (modalType === 'Withdraw') {
            setModalHeader(<>{modalType} to Wallet</>)
             setModalInfo(<><WithdrawFiat accountBalance={accountBalance}/></>)
           }
    }
    const [modalType, setModalType] = useState('')
    const [modalHeader, setModalHeader] = useState('')
    const [modalInfo, setModalInfo] = useState('')
    const [accountBalance, setAccountBalance] = useState()

    return (<>
     {showFiat ? <> <CurrencyWrapper >****** *****</CurrencyWrapper></> : 
        <CurrencyWrapper>
            <CurrencyFormat value={accountBalance} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                isNumericString={true} thousandSeparator={true} prefix={`${fiatCurrency}`} />

        </CurrencyWrapper>
        }
        <FlexRow>
             <Link className={styles.main_balance_currency} to="#" data-bs-toggle="modal" data-bs-target="#fundAccountModal" id="Deposit" onClick={OpenModal}>Add Funds</Link>
             <Link className={styles.main_balance_currency} to="#" data-bs-toggle="modal" data-bs-target="#fundAccountModal" id="Withdraw" onClick={OpenModal}>Withdraw Funds</Link>
        </FlexRow>

<div className="modal fade" id="fundAccountModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className= "modal-dialog modal-dialog-centered modal-md">
    <div className={`  ${styles.card_inner} modal-content `}>
    <div className={`${styles.card_header} modal-header`}>
        <div className={`  ${styles.card_header_text}`} id="fundAccountModalLabel">{modalHeader}</div>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> 
      <div className={`${styles.card_body} modal-body`}>
          {
             modalInfo
               
          }
      
      </div>
    </div>
  </div>
</div>

    </>);
}


export default FiatWallet;
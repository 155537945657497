import logo from '../../images/ProfolioTrades.png'
import { Link } from "react-router-dom";
import styles from '../style/main.module.css'
import { useState, useEffect} from 'react';
import '../style/flag-icons-main/css/flag-icons.min.css'
import '../style/darkMode.css'
import { useNavigate  } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import {QRCode} from "react-qrcode-logo";
import React  from 'react';
import { UserLogoMobile, DropdownMobile, Logo } from './headerStyle';
import Cookies from 'js-cookie';
import axios from 'axios';
const DashboardHeader = () => {
  const [email, setEmail] = useState()
  const [accountBalance, setAccountBalance] = useState()
  const [verified, setVerified] = useState()
  const [currencySymbols, setCurrencySymbols] = useState()
  const [currencyName, setCurrencyName] = useState()
  const [flag, setFlag] = useState()
  // const [state, setState] = useState()


const navigate  = useNavigate();

  useEffect(()=>{
    
    document.title = `Profolio Market | Dashboard`;
    

    const check = Cookies.get('__isLoggedIn')
		if (check) {
      const userInfo = localStorage.getItem('_currentInfo')
      const currInfo =JSON.parse(localStorage.getItem('OMS__CUR')) 
      const users = JSON.parse(userInfo)
  
      // setState(users)

      setEmail(users.email)
    axios.post(`https://api.profoliomarket.com/user/getVerificationStatus`,{userId: users.user_id})
      .then((res)=>{

//console.log(res)

              if (res.data.data === "0"){
                setVerified('Invalid user')
            } else if (res.data.data === "1"){
              setVerified('Unverified')
            }else if (res.data.data === "2" ){
            setVerified('Under Review')
          }else if (res.data.data === "3" ){
          setVerified('Verified')
          const updateInfo = {
            country: users.country,
            currency_id: users.currency_id,
            demo: users.demo,
            deposit: users.deposit,
            email: users.email,
            firstName: users.firstName,
            lastLog: users.lastLog,
            phone: users.phone,
            lastName: users.lastName,
            user_id: users.user_id,
            verificationStatus: "3"
        }
        localStorage.setItem('_currentInfo', JSON.stringify(updateInfo))
        }
      })
      .catch((error)=>{
        console.log(error.response)
      })



    

       setCurrencySymbols(currInfo.symbol)
       setCurrencyName(currInfo.name)
      // setFlag((currInfo.name).toLowerCase())
  
      axios.post('https://api.profoliomarket.com/user/getFiat',{currencyId:users.currency_id})
      .then((response)=>{
        //console.log(response.data)
        const curr =  response.data.currency
        let sym = curr.symbol
        let result = sym.substring(1, 2);
        const items ={
        name: curr.name,
        symbol: result
        }
        localStorage.setItem('OMS__CUR', JSON.stringify(items))
      })
      .catch((error)=>{
        console.log(error.response)
      })

  
       axios.post('https://api.profoliomarket.com/auth/getDeviceInfo',{})
       .then((response) => {      
           return(response.data)
       })
       .then((data)=>{
        setFlag((data.country).toLowerCase())
         // console.log(data)
          //  axios.get(`http://ip-get-geolocation.com/api/json/${data.message}`)
          //   .then((response) => {
          //      // console.log(response)
          //      setFlag((response.data.countryCode).toLowerCase()) 
              //  const count = getAllInfoByISO(`${(response.data.countryCode).toLowerCase()}`)
              //  setFlag(count.currency)
            //})
       })

         axios.post('https://api.profoliomarket.com/user/getOtherBalance', { userId: users.user_id })
            .then(response => {
                return response.data.data
  
            }).then((data) => {   
              
                if (data !== undefined){
                   const balance = data.fiat
                setAccountBalance(balance)  
                }
               
                    
                //setBonusBalance(data.bonus)
             //  setTotalTrades(data.length)
            })
   
      
        
		}
else{
  	navigate('/login')
}
   
    },[navigate])
  

  let [theme, setTheme] = useState()


  const setDark = () => {
localStorage.setItem("theme", "dark");
document.documentElement.setAttribute("data-theme", "dark");

};

const setLight = () => {
localStorage.setItem("theme", "light");
document.documentElement.setAttribute("data-theme", "light");
setTheme(<i className="fi fi-sr-moon"></i>) 
};

const storedTheme = localStorage.getItem("theme");

const prefersDark =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

const defaultDark =
  storedTheme === "dark" || (storedTheme === null && prefersDark);

if (defaultDark) {
  setDark();

}
const [toggler, toggleTheme] = useState(defaultDark)

if(toggler){
  //console.log(toggler)
   theme = <i className="fi fi-sr-sun"></i>
}else{
 theme =  <i className="fi fi-sr-moon"></i>
}
  const switchTheme = ()=>{
  
    if (theme.props.className === 'fi fi-sr-sun'){
      setLight();
       setTheme(<i className="fi fi-sr-moon"></i>)
       toggleTheme(false)
    }
    else if (theme.props.className === 'fi fi-sr-moon'){
      setDark();
      setTheme(<i className="fi fi-sr-sun"></i>) 
      toggleTheme(true)
   }
  }

  const logout =() =>{
    Cookies.remove('__isLoggedIn')
    navigate('/login')
    localStorage.clear()
  }


    return (
      <>
  <header className={`${styles.dark_bb} navbar-dark`}>
    <nav className={`${styles.navbar} navbar navbar-expand-lg`}>
    <UserLogoMobile >
            <Link className={`${styles.link_icons} ${styles.menu_link}  nav-link `} to="#" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fi fi-sr-portrait"></i>
            </Link>
            <DropdownMobile className={`dropdown-menu`}>
            <div className={`${styles.user_card_head} `}>
              <div className={`${styles.user_card_head_top} `}>
                {email}
              </div>
              <div className={`${styles.user_card_head_buttom} `}>
                 <div className={styles.user_card_head_balance}>
                 <CurrencyFormat value={accountBalance} displayType={'text'} decimalScale={2} fixedDecimalScale={true} isNumericString={true} thousandSeparator={true} prefix={`${currencySymbols}`} /> 

                   </div>
                   <Link to="#" className={styles.user_card_head_verification}>
                   <i className="fi fi-sr-file-delete"></i> {verified}
                   </Link>
              </div>
            </div>
            <div className={styles.user_card_body}>

            </div>
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="dashboard">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-home ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Dashboard</div>
                      </div>
                    </Link>
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="order">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-signal-alt-2 ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Orders</div>
                      </div>
                    </Link>
                    {/* <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="?l=market-capital">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-bank ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Financials</div>
                      </div>
                    </Link> */}
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="security">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-fingerprint ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Security</div>
                      </div>
                    </Link>
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="verification">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-document ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Verification</div>
                      </div>
                    </Link>
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="affiliate">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-comment-user ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Affiliate Program</div>
                      </div>
                    </Link>
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="support">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-life-ring  ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Help/Support</div>
                      </div>
                    </Link>
                    <div className={`dropdown-item center ${styles.items} ${styles.user_card_body_link}`}> 
                      <div className={styles.user_card_qr}>
                        <div className={styles.user_card_qr_content} >
                        <QRCode value={`referral Link comes Here ${email}`} size={100} level={'M'} 
                        eyeRadius={[[10, 10, 0, 10], [10, 10, 10, 0],	[10, 0, 10, 10],]} 
                        logoImage={logo} logoOpacity={1} qrStyle={'squares'} style={{borderRadius:'10px'}} className="rounded"/>
                        </div>
                      </div>
                      <button onClick={logout} className={`ms-auto btn btn-warning ${styles.user_card_head_verification}`}>
                      <div className={styles.user_card_icon_body}>
                        <i className="fi fi-sr-power"></i></div>  
                        <div className={styles.user_card_logout}>
                        <div className={styles.user_card_text_inner}>Log Out</div>
                      </div>
                      </button>
                    </div>
                    </DropdownMobile>
          </UserLogoMobile>
      <Link className="navbar-brand" to="#">
        <Logo src={logo} alt="logo"/></Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#headerMenu" aria-controls="headerMenu" aria-expanded="false" aria-label="Toggle navigation">
      &#9776;
    </button>
    
      <div className="collapse navbar-collapse" id="headerMenu">
        <div className={`${styles.menu } ${styles.firstMobile} me-auto`}>
          <div className={`${styles.menu_body}`}>
            <Link className={`${styles.menu_link} nav-link dropdown-toggle`} to="#" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Markets
            </Link>
            <ul className={`dropdown-menu ${styles.menu_dropdown} `}>
            
              <Link className={`dropdown-item ${styles.items} `} to="?l=market-capital">Markets Line</Link>
              <Link className={`dropdown-item ${styles.items} `} to="?l=market-screener">Market Screener</Link>
              <Link className={`dropdown-item ${styles.items} `} to="?l=market-crypto">Market Crypto</Link>
            </ul>
          </div>
          
          <div className={styles.menu_body}>
            <Link className={`${styles.menu_link} nav-link `} to="?l=cross-rate" role="button">
             Cross Rates
            </Link>
            
          </div>
          <div className={styles.menu_body}>
            <Link className={`${styles.menu_link} nav-link `} to="?l=symbol-info" role="button">
             Symbol Info
            </Link>
            
          </div>
          <div className={styles.menu_body}>
            <Link className={`${styles.menu_link} nav-link `} to="?l=heat-map" role="button">
            Heat Map
            </Link>
            
          </div>
  
        </div>
          

        <div className={`${styles.menu} ${styles.baseMobile} ms-auto`}>
        <div className={`${styles.menu_body} ${styles.mobileEmpty}`}>
            <Link className={` ${styles.link_icons} ${styles.menu_link} nav-link`} to="#" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fi fi-sr-wallet"></i>
            </Link>
            <ul className={`dropdown-menu ${styles.menu_dropdown} `}>
              <Link className={`dropdown-item ${styles.items} `} to='/home/wallet'>
              <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-apps ${styles.user_card_icons}`}></i> </div>
                        <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Overview</div>
                      </div>
                  </Link>
              <Link className={`dropdown-item ${styles.items} `} to="/home/wallet/spot">
              <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-apps-add ${styles.user_card_icons}`}></i> </div>
                        <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Spot and Fiat</div>
                      </div>
                      </Link>
              <Link className={`dropdown-item ${styles.items} `} to="/home/connectWallect">
              <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-link-alt ${styles.user_card_icons}`}></i> </div>
                        <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}> External Wallets</div>
                      </div>
                     </Link>
            </ul>
          </div>
         
          <div className={`${styles.menu_body} ${styles.mobileEmpty}`}>
            <Link className={`${styles.link_icons} ${styles.menu_link}  nav-link `} to="#" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fi fi-sr-portrait"></i>
            </Link>
            <ul className={`dropdown-menu ${styles.menu_dropdown} ${styles.user_card}`}>
            <div className={`${styles.user_card_head} `}>
              <div className={`${styles.user_card_head_top} `}>
                {email}
              </div>
              <div className={`${styles.user_card_head_buttom} `}>
                 <div className={styles.user_card_head_balance}>
                 <CurrencyFormat value={accountBalance} displayType={'text'} decimalScale={2} fixedDecimalScale={true} isNumericString={true} thousandSeparator={true} prefix={`${currencySymbols}`} /> 

                   </div>
                   <Link to="#" className={styles.user_card_head_verification}>
                   <i className="fi fi-sr-file-delete"></i> {verified}
                   </Link>
              </div>
            </div>
            <div className={styles.user_card_body}>

            </div>
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="dashboard">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-home ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Dashboard</div>
                      </div>
                    </Link>
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="/home/order">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-signal-alt-2 ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Orders</div>
                      </div>
                    </Link>
                    {/* <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="?l=market-capital">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-bank ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Financials</div>
                      </div>
                    </Link> */}
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="/home/security">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-fingerprint ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Security</div>
                      </div>
                    </Link>
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="verification">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-document ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Verification</div>
                      </div>
                    </Link>
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="affiliate">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-comment-user ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Affiliate Program</div>
                      </div>
                    </Link>
                    <Link className={`dropdown-item ${styles.items} ${styles.user_card_body_link}`} to="support">
                      <div className={styles.user_card_icon_body}>
                        <i className={`fi fi-sr-life-ring  ${styles.user_card_icons}`}></i> </div>
                      <div className={styles.user_card_text_body}>
                        <div className={styles.user_card_text_inner}>Help/Support</div>
                      </div>
                    </Link>
                    <div className={`dropdown-item center ${styles.items} ${styles.user_card_body_link}`}> 
                      <div className={styles.user_card_qr}>
                        <div className={styles.user_card_qr_content} >
                        <QRCode value={`referral Link comes Here ${email}`} size={100} level={'M'} 
                        eyeRadius={[[10, 10, 0, 10], [10, 10, 10, 0],	[10, 0, 10, 10],]} 
                        logoImage={logo} logoOpacity={1} qrStyle={'squares'} style={{borderRadius:'10px'}} className="rounded"/>
                        </div>
                      </div>
                      <button onClick={logout} className={`ms-auto btn btn-warning ${styles.user_card_head_verification}`}>
                      <div className={styles.user_card_icon_body}>
                        <i className="fi fi-sr-power"></i></div>  
                        <div className={styles.user_card_logout}>
                        <div className={styles.user_card_text_inner}>Log Out</div>
                      </div>
                      </button>
                    </div>
                    </ul>
          </div>
          <div className={`${styles.menu_body} `}>
            <Link className={`${styles.menu_link} ${styles.link_icons}  nav-link`} to="#" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fi fi-sr-bell"></i>
            </Link>
            <ul className={`dropdown-menu ${styles.menu_dropdown} ${styles.notification}`}>
                    <div className={`${styles.notification_header} `}>
                      <div className={`${styles.notification_header_summary} `}>
                        <div className={`${styles.note_header}`}>1</div>
                        <div className={`${styles.note_text}`}>New Notifications</div>
                      </div>
                      <Link to="?l=market-capital" className={styles.note_last}>
                        <div className={styles.note_last_item}>
                          View All
                        </div>
                        <i className="fi fi-rr-arrow-small-right"></i></Link>
                    </div>
                    <div className={styles.notification_body}>
                      <div className={styles.notification_body_card}>
                        <div className={styles.note_body_left}>
                          <div className={styles.note_body_left_content}>
                            <i className="fi fi-sr-envelope"></i>
                          </div>
                        </div>
                        <div className={styles.note_body_right}>
                          <div className={styles.note_body_right_title}>Help Us secure your Account</div>
                          <div className={styles.note_body_right_content} style={{    overflow: 'hidden', WebkitLineClamp: 1}}>
                            {/* The system has detected that your account is logged in from an unused IP address.
                            Account: fergusonff2@gmail.com
                            Device: unknown
                            Time: 2022-02-03 10:25:27(UTC)
                            IP: 102.91.5.166
                            If this activity is not your own operation, please disable your account and contact us immediately. */}</div>
                          <div className={styles.note_body_right_time}>3hrs ago</div>
                        </div>
                      </div>
                   

                    </div>
            </ul>
          </div>
          <div className={`${styles.menu_body}`}>
          <Link className={`${styles.menu_link}  nav-link`} to="#" type="button" >
            <i className={`fl fl-${flag}`}></i> | {currencyName}
            </Link>
          </div>
          <div className={`${styles.menu_body}`}>
            <Link className={`${styles.menu_link} ${styles.link_icons} nav-link `} to="#" type="button" onClick={switchTheme}>
           {theme}
            </Link>    
                  <input type="checkbox" id="checkbox" onChange={(e)=>toggleTheme(e.target.checked)} defaultChecked={toggler}
                  style={{display:'none'}}/>
          </div>
          {/* <div className="">
            <Link className="btn btn-outline-warning dropdown-toggle " to="#" role="button" data-toggle="dropdown" 
            aria-haspopup="true" aria-expanded="false" id="activeBalance">Demo Account: $7,655.66</Link>

            <div className="dropdown-menu">
              <button className=" btn btn-success" type="button" id="toLiveChange">Live Account: $5,004.00
              </button>
            </div>
             </div> */}
          </div>

      </div>
    </nav>
  </header>
        
      </>
    );
}
 
export default DashboardHeader;
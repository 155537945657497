import styled from 'styled-components';

export const DisplayMobile = styled.div`
@media (min-width: 768px){
display:none;
}
display: flex;
`
export const UserLogoMobile = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 14px;
    outline: none;
    cursor: default;
    color: #edeff1;
    align-items: center;
    position: relative;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
    height: 100%;
    @media (min-width: 768px){
        display:none;
        }
        display: flex;
`
export const DropdownMobile = styled.ul`
box-sizing: border-box;
margin: 0px;
background-color: rgb(30, 35, 41);
position: absolute;
box-shadow: rgb(0 0 0 / 8%) 0px 0px 20px;
overflow: hidden;
border-radius: 0px 0px 8px 8px;
width: 100%;
min-width:300px;
left: 0 !important;`
export const Logo = styled.img`
width: 150px;`